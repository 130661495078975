import React, { useState, useEffect, PureComponent, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';


import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import MultiBarChart from "../components/graphs/MultiBarChart";
import Tooltip from "@mui/material/Tooltip";
import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";
import IneligibleReasonBarChart from "../components/graphs/IneligibleReasonBarChart";
import IneligibleExtendedGraphCard from "../components/cards/IneligibleExtendedGraphCard";
import { RolesContext, SidebarContext } from "../App";
import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, GraphDetailsContext } from "../App";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest, b2cPolicies } from "../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetch from "../hooks/useFetch";
import Badge from '@mui/material/Badge';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
//project page
function Project({ onChange, children }) {

    const [responseRateData, setResponseRateData] = useState()
    const [employeeStatusData, setEmployeeStatusData] = useState()
    const [dependentStatusData, setDependentStatusData] = useState()
    const [ineligibleStatusData, setIneligibleStatusData] = useState()
    const [incompleteStatusData, setIncompleteStatusData] = useState()
    const {
        setSidebarTitle,
        setSidebarActions,
        sidebarActiveClientID,
        setSidebarActiveClientID
    } = useContext(SidebarContext);

    const { graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData } = useContext(GraphDetailsContext)


    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);
    const { accounts, instance } = useMsal();
    const { id, auditName } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoLists, setIsTwoLists] = useState(false);
    const [firstIneligibleList, setFirstIneligibleList] = useState([]);
    const [secIneligibleList, setSecIneligibleList] = useState([]);
    const [finalIneligibleList, setFinalIneligibleLst] = useState([]);
    const [hideIneligibleChart, setHideIneligibleChart] = useState(false);
    const [validRoute, setValidRoute] = useState(null);
    const [showIneligibleChart, setShowIneligibleChart] = useState(true);
    const [showIncompleteChart, setShowIncompleteChart] = useState(true);
    const [popAlertToast, setPopAlertToast] = useState(false);
    const fetchDataRef = useRef(false);
    const [promiseIsLoading, setPromiseIsLoading] = useState(true);

    const [familyReport, setFamilyReport] = useState([]);
    const [dependentReport, setDependentReport] = useState([]);
    const [alertReport, setAlertReport] = useState([]);
    const [ineligibleReport, setIneligibleReport] = useState([]);
    const [incompleteReport, setIncompleteReport] = useState([]);





    useEffect(() => { //called when route params are set 

        if (id !== "undefined") {
            if (id !== sidebarActiveClientID) {
                setIsLoading(false);
                setPromiseIsLoading(true);
            }
        } else if (id === "undefined") { //if id is undefined and user is not rerouted (due to permissions handling) this means user is most likely a client with no active audits.
            setIsLoading(true);
            setPromiseIsLoading(true);
            setSidebarActiveClientID(' ');
            setSidebarTitle(' ');
            toast.info("There are no active audits", {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                toastId: "noActiveAudits"
            });
        }

     

    }, [id, auditName]);


    useEffect(() => {

        if (isLoading === false && id !== "undefined") {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)
            setPopAlertToast(false)
            setIsLoading(true);
            setPromiseIsLoading(true);

        } else if (id === "undefined") {
            setPromiseIsLoading(true);
        }

        //if (isLoading === false && id === undefined) { //if id is undefined and user is not rerouted (due to permissions handling) this means user is most likely a client with no active audits.
        //    setSidebarActiveClientID('')
        //    setSidebarTitle('')
        //    console.log("setSideBar call - ", auditName)
        //    setPopAlertToast(false)
        //    setIsLoading(true);
        //    setPromiseIsLoading(true);
        //    toast.info("There are no active audits", {
        //        position: "bottom-center",
        //        theme: "colored",
        //        autoClose: false,
        //        closeOnClick: true,
        //        toastId: "noActiveAudits"
        //    });


        //}


    }, [isLoading]);

    const { newRoutes, setRoutes } = useContext(RoutesContext);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        {
            id: 4,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: false,
            style: '',
            isNested: false
        }
    ]);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);

    const [familyStatusTableDataTemp, setFamilyStatusTableDataTemp] = useState([]);

    useEffect(() => {


        if ((roles === "SERVICES")) {
            navigate('/processing');
        }
    
        console.log("valid Route --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            setIsLoading(false);

        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: false
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: true
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 5,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles, validRoute]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        setIneligibleStatusData()
        setFirstIneligibleList([])
        setFinalIneligibleLst([])
        setSecIneligibleList([])
        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)
                setCount(count + 1);
            }

        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null) && (count === permissions.length)) {
            setValidRoute(false)
        }
        console.log("current id --- ", id);
     



    }, []);
    

    const [extendIneligibleGraphContainer, setExtendIneligibleGraphContainer] = useState(false);

    const  failedFetchError = (status) => {
       
        toast.error(String(status) + " error: Please refresh and try again.", {
            position: "bottom-center",
            theme: "colored",
            autoClose: false,
            closeOnClick: true,
            toastId: "failedToFetch"
        });


    }


    useEffect(() => {

        console.log("isLoading called - ", isLoading);

        if (isLoading === false && id !== "undefined") {

            const fetchResponseRate = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter;
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetResponseRate', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then((res) => {
                       
                        if (res.ok) {
                            retry = 0;
                            response = res.json();
                            return;
                            //return res.json();
                        }
                        if (retry > 1) {
                            retry--;
                            counter++;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }
                      
                    }).catch((error) => { console.log(error) })
                }
               
                return response;
            };

            const fetchFamilyStatus = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter;
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatus', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then(res => {

                        if (res.ok) {
                            retry = 0;
                            //return res.json();
                            response = res.json();
                            return;
                        }
                        if (retry > 1) {
                            retry--;
                            counter++;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }

                    }).catch((error) => { console.log(error) })
                }
                return response;
            };

            const fetchDependentStatus = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter; //exponentially delay fetch request
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatus', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then(res => {

                        if (res.ok) {
                            retry = 0
                            //return res.json();
                            response = res.json();
                            return;
                        }
                        if (retry > 1) {
                            counter++;
                            retry--;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }

                    })
                    .catch((error) => { console.log(error) })
                }
                return response;
            };

            const fetchAlertStatus = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter;
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then(res => {

                        if (res.ok) {
                            retry = 0
                            //return res.json(); 
                            response = res.json();
                            return;
                        }
                        if (retry > 1) {
                            retry--;
                            counter++;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }

                    }).catch((error) => { console.log(error) })
                }
                return response;
            };
            const fetchIneligibleStatus = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter;
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleStatus', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then(res => {

                        if (res.ok) {
                            retry = 0;
                            response = res.json();
                            return;
                        }
                        if (retry > 1) {
                            retry--;
                            counter++;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }

                    }).catch((error) => { console.log(error) })
                }
                return response;
            };

            const fetchIncompleteStatus = async (token) => {
                let retry = 5;
                let response = null;
                let counter = 1;
                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
                while (retry > 0) {
                    if (retry < 5) {
                        let d = 2000 * counter;
                        await timeout(d);
                    }
                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteStatus', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ "auditId": parseInt(id) })
                    }).then(res => {
                        if (res.ok) {
                            retry = 0
                            response = res.json();
                            return;
                        }
                        if (retry > 1) {
                            retry--;
                            counter++;
                            return;
                        }
                        if (retry <= 1) {
                            retry = 0;
                            failedFetchError(res.status); //throw error 
                            return;
                        }

                  
                    })
                    .catch((error) => { console.log(error) })
                }

                
                return response;
            };

            const fetchDependentData = async (token) => {
                let response = null;
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatusData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": id })
                })
                    .then(res => { response = res.json() })
                    .catch((error) => {
                        console.log(error)
                    })



                return response;

            }

            const fetchEmployeeData = async (token) => {
                let response = null;
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatusData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,

                    },
                    body: JSON.stringify({ "auditId": id })
                })
                    .then(res => { response = res.json() })
                    .catch((error) => {
                        console.log(error)
                    })

                return response;
            }

            const fetchAlertData = async (token) => {
                let response = null;
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": id })
                })
                    .then(res => { response = res.json() })
                    .catch((error) => {
                        console.log(error)
                    })

                return response;
            }


            const fetchIneligibleData = async (token) => {
                let response = null;
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleReportData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": id })
                })
                    .then(res => { response = res.json() })
                    .catch((error) => {
                        console.log(error)
                    })
                return response;
            }
            const fetchIncompleteData = async (token) => {
                let response = null;
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteReportData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": id })
                })
                    .then(res => { response = res.json() })
                    .catch((error) => {
                        console.log(error)
                    })
                return response;
            }

            //const fetchInPromise = async () => {
            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {

            //                    await Promise.allSettled([
            //                        fetchResponseRate(result.idToken), //result[0]
            //                        fetchFamilyStatus(result.idToken), //result[1]
            //                        fetchDependentStatus(result.idToken), //result[2]
            //                        fetchAlertStatus(result.idToken), //result[3]
            //                        fetchIneligibleStatus(result.idToken), //result[4]
            //                        fetchIncompleteStatus(result.idToken), //result[5]
            //                        fetchEmployeeData(result.idToken), //result[6]
            //                        fetchDependentData(result.idToken),//result[7]
            //                        //fetchAlertData(result.idToken), //result[8]
            //                        fetchIneligibleData(result.idToken), //result[8]
            //                        fetchIncompleteData(result.idToken), //result[9]

            //                    ])
            //                        .then((result) => {

            //                            /* REPORT DATA */
            //                            setFamilyReport(result[6]?.value);
            //                            setDependentReport(result[7]?.value);
            //                            setAlertReport(result[3]?.value);
            //                            setIneligibleReport(result[8]?.value);
            //                            setIncompleteReport(result[9]?.value);


            //                            /* GRAPH DATA */
            //                            console.log("PROJECT FETCH RESULT - ", result);
            //                            setResponseRateData(result[0]?.value);
            //                            setEmployeeStatusData(result[1]?.value);
            //                            setDependentStatusData(result[2]?.value);
       
            //                            if (result[3]?.value?.length > 0) {
            //                                setAlertNoteCount(result[3]?.value?.length);
            //                                setReportBadge(false);
            //                                setPopAlertToast(true);
            //                            }

            //                            if (result[3]?.value?.length === 0) {
            //                                setAlertNoteCount(0);
            //                                setReportBadge(true);
            //                            }
            //                            setFirstIneligibleList([])
            //                            setFinalIneligibleLst([])
            //                            setSecIneligibleList([])
            //                            setIneligibleStatusData(result[4]?.value);

            //                            if (result[4]?.value?.notEligible > 0) {
            //                                console.log("not eligible + 1");
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Relationship Not Eligible",
            //                                    metricValue: result[4]?.value?.notEligible,
            //                                    metricPercentage: Number(result[4]?.value?.notEligiblePercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.exceedsAgeRequirements > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Exceeds Age Requirements",
            //                                    metricValue: result[4]?.value?.exceedsAgeRequirements,
            //                                    metricPercentage: Number(result[4]?.value?.exceedsAgeRequirementsPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.relationshipNotSupportedByDoc > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Relationship Not Supported By Docs",
            //                                    metricValue: result[4]?.value?.relationshipNotSupportedByDoc,
            //                                    metricPercentage: Number(result[4]?.value?.relationshipNotSupportedByDocPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.divorced > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Divorced",
            //                                    metricValue: result[4]?.value?.divorced,
            //                                    metricPercentage: Number(result[4]?.value?.divorcedPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.legalSeparation > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Legal Separation",
            //                                    metricValue: result[4]?.value?.legalSeparation,
            //                                    metricPercentage: Number(result[4]?.value?.legalSeparationPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.notFullTimeStudent > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Not Full-Time Student",
            //                                    metricValue: result[4]?.value?.notFullTimeStudent,
            //                                    metricPercentage: Number(result[4]?.value?.notFullTimeStudentPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.military > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Military",
            //                                    metricValue: result[4]?.value?.military,
            //                                    metricPercentage: Number(result[4]?.value?.militaryPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.employeeRequest > 0) {
            //                                console.log("employee request + 1");
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Employee Request",
            //                                    metricValue: result[4]?.value?.employeeRequest,
            //                                    metricPercentage: Number(result[4]?.value?.employeeRequestPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.qualifiedEvent > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Qualified Event",
            //                                    metricValue: result[4]?.value?.qualifiedEvent,
            //                                    metricPercentage: Number(result[4]?.value?.qualifiedEventPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.eligibleForOtherCoverage > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Eligible for Other Coverage",
            //                                    metricValue: result[4]?.value?.eligibleForOtherCoverage,
            //                                    metricPercentage: Number(result[4]?.value?.eligibleForOtherCoveragePercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForDentalEligibleForMedVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
            //                                    metricValue: result[4]?.value?.ineligibleForDentalEligibleForMedVision,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForMedicalDentalEligibleVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
            //                                    metricValue: result[4]?.value?.ineligibleForMedicalDentalEligibleVision,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForMedVisionEligibleForDental > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
            //                                    metricValue: result[4]?.value?.ineligibleForMedVisionEligibleForDental,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForMedEligibleDentalVision > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
            //                                    metricValue: result[4]?.value?.ineligibleForMedEligibleDentalVision,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForDentalVisionEligibleForMed > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
            //                                    metricValue: result[4]?.value?.ineligibleForDentalVisionEligibleForMed,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
            //                                }])
            //                            }

            //                            if (result[4]?.value?.ineligibleForVisionEligibleMedicalDental > 0) {
            //                                setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
            //                                    metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
            //                                    metricValue: result[4]?.value?.ineligibleForVisionEligibleMedicalDental,
            //                                    metricPercentage: Number(result[4]?.value?.ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
            //                                }])
            //                            }
            //                            if (result[4]?.value?.total === 0) {
            //                                setShowIneligibleChart(false);
            //                            }

            //                            setIncompleteStatusData(result[5]?.value);
            //                            if (result[5]?.value?.total === 0) {
            //                                setShowIncompleteChart(false);
            //                            }

            //                            setPromiseIsLoading(false);
            //                        })
            //                        .catch((error) => {
            //                            console.log('promise error -', error); //promise failed
            //                            //abort fetch request here
            //                        });


            //                }
                         
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }
            //}
            const fetchInPromise = async () => {
                if (accounts.length > 0) {
                    await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                        .then(result => {
                            const fetchData = async () => {

                                await Promise.allSettled([
                                    //fetchResponseRate(result.idToken), //result[0]

                                    //fetchEmployeeData(result.idToken), //result[6]
                                    //fetchDependentData(result.idToken),//result[7]
                                    //fetchAlertData(result.idToken), //result[8]
                                    //fetchIneligibleData(result.idToken), //result[8]
                                    //fetchIncompleteData(result.idToken), //result[9]

                                    fetchFamilyStatus(result.idToken), //result[0]  //result[0]?.value?.employeeGraphDto === result[1], result[0]?.value?.employeStatusReports === result[6]. result[0]?.value?.responseGraphDto === result[0] 
                                    fetchDependentStatus(result.idToken), //result[1] //result[1]?.value?.dependentGraphDto === result[2], result[1]?.value?.dependentStatusReports === result[7]
                                    fetchAlertStatus(result.idToken), //result[2] //result[2]?.value?.alertReportData === result[3]
                                    fetchIneligibleStatus(result.idToken), //result[3] //result[3]?.value?.ineligibleGraphDto === result[4], result[3]?.value?.ineligibleReports === result[8]
                                     fetchIncompleteStatus(result.idToken), //result[4] // result[4]?.value?.incompleteGraphDataDto ===  result[5], result[4]?.value?.incompelteReports === result[9]

                                ])
                                    .then((result) => {
                                        console.log("PROJECT FETCH RESULT - ", result);
                                        /* REPORT DATA */
                                        setFamilyReport(result[0]?.value?.employeeStatusReports);
                                        setDependentReport(result[1].value?.dependentStatusReports);
                                        setAlertReport(result[2]?.value?.alertReportData);
                                        setIneligibleReport(result[3]?.value?.ineligibleReports);
                                        setIncompleteReport(result[4]?.value?.incompleteReports);


                                        /* GRAPH DATA */
                                        setResponseRateData(result[0]?.value?.responseGraphDto);
                                        setEmployeeStatusData(result[0]?.value?.employeeGraphDto);
                                        setDependentStatusData(result[1]?.value?.dependentGraphDto);

                                        if (result[2]?.value?.alertReportData?.length > 0) {
                                            setAlertNoteCount(result[2]?.value?.alertReportData?.length);
                                            setReportBadge(false);
                                            setPopAlertToast(true);
                                        }

                                        if (result[2]?.value?.alertReportData?.length === 0) {
                                            setAlertNoteCount(0);
                                            setReportBadge(true);
                                        }
                                        setFirstIneligibleList([])
                                        setFinalIneligibleLst([])
                                        setSecIneligibleList([])
                                        setIneligibleStatusData(result[3]?.value?.ineligibleGraphDto);

                                        if (result[3]?.value?.ineligibleGraphDto?.notEligible > 0) {
                                            console.log("not eligible + 1");
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Relationship Not Eligible",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.notEligible,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.notEligiblePercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.exceedsAgeRequirements > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Exceeds Age Requirements",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.exceedsAgeRequirements,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.exceedsAgeRequirementsPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.relationshipNotSupportedByDoc > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Relationship Not Supported By Docs",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.relationshipNotSupportedByDoc,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.relationshipNotSupportedByDocPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.divorced > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Divorced",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.divorced,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.divorcedPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.legalSeparation > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Legal Separation",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.legalSeparation,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.legalSeparationPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.notFullTimeStudent > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Not Full-Time Student",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.notFullTimeStudent,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.notFullTimeStudentPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.military > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Military",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.military,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.militaryPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.employeeRequest > 0) {
                                            console.log("employee request + 1");
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Employee Request",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.employeeRequest,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.employeeRequestPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.qualifiedEvent > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Qualified Event",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.qualifiedEvent,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.qualifiedEventPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.eligibleForOtherCoverage > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Eligible for Other Coverage",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.eligibleForOtherCoverage,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.eligibleForOtherCoveragePercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalEligibleForMedVision > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Dental, Eligible: Med, Vision",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalEligibleForMedVision,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalEligibleForMedVisionPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForMedicalDentalEligibleVision > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Dental, Med, Eligible: Vision",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForMedicalDentalEligibleVision,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForMedicalDentalEligibleVisionPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForMedVisionEligibleForDental > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Vision, Med, Eligible: Dental",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForMedVisionEligibleForDental,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForMedVisionEligibleForDentalPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForMedEligibleDentalVision > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Med, Eligible: Dental, Vision",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForMedEligibleDentalVision,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForMedEligibleDentalVisionPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalVisionEligibleForMed > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Vision, Dental, Eligible: Med",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalVisionEligibleForMed,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForDentalVisionEligibleForMedPercent * 100).toFixed(2),
                                            }])
                                        }

                                        if (result[3]?.value?.ineligibleGraphDto?.ineligibleForVisionEligibleMedicalDental > 0) {
                                            setFinalIneligibleLst(finalIneligibleList => [...finalIneligibleList, {
                                                metricTitle: "Ineligible: Vision, Eligible: Med, Dental",
                                                metricValue: result[3]?.value?.ineligibleGraphDto?.ineligibleForVisionEligibleMedicalDental,
                                                metricPercentage: Number(result[3]?.value?.ineligibleGraphDto?.ineligibleForVisionEligibleMedicalDentalPercent * 100).toFixed(2),
                                            }])
                                        }
                                        if (result[3]?.value?.ineligibleGraphDto?.total === 0) {
                                            setShowIneligibleChart(false);
                                        }

                                        setIncompleteStatusData(result[4]?.value?.incompleteGraphDataDto);
                                        if (result[4]?.value?.incompleteGraphDataDto?.total === 0) {
                                            setShowIncompleteChart(false);
                                        }

                                        setPromiseIsLoading(false);
                                    })
                                    .catch((error) => {
                                        console.log('promise error -', error); //promise failed
                                        //abort fetch request here
                                    });


                            }

                            fetchData();
                        }).catch((error) => {
                            console.log(error) //token expired/is not valid => user is redirected to login page
                            if (error instanceof InteractionRequiredAuthError) {
                                instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                            }
                        });
                }
            }

            fetchInPromise();
           
           
   
        }
       

    }, [sidebarActiveClientID, accounts, id, instance, isLoading]);

    useEffect(() => {
        if (toast.isActive("newAlertsNotification")) {
            toast.update("newAlertsNotification", {
                type: toast.TYPE.ERROR,
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                
            })
        }else if ((popAlertToast === true) && (!toast.isActive("newAlertsNotification"))){
            toast.error("Alerts are available to export", {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                toastId: "newAlertsNotification"
               
            });
        }

    }, [popAlertToast]);

    useEffect(() => {
        setSecIneligibleList([])
        setFirstIneligibleList([])
        if (finalIneligibleList.length > 8) {
            setExtendIneligibleGraphContainer(true);
            for (let i = 0; i < finalIneligibleList.length; i++) {
                if (i <= 7) {
                    setFirstIneligibleList(firstIneligibleList => [...firstIneligibleList, finalIneligibleList[i]])
                }

                if (i > 7) {
                    setSecIneligibleList(secIneligibleList => [...secIneligibleList, finalIneligibleList[i]])
                }

            }
        }

        if (finalIneligibleList.length === 0) {
            setHideIneligibleChart(true);
        }


    }, [finalIneligibleList]);



    /* RESPONSE RATE GRAPH DATA */
    let RRGrandTotal = responseRateData?.responseRateGrandTotal;
    let RRNoResponsePercent = Number(responseRateData?.responseRateNoResponsePercent * 100).toFixed(2);
    let RRNoResponseTotal = responseRateData?.responseRateNoResponseTotal;
    let RRRespondedPercent = Number(responseRateData?.responseRateRespondedPercent * 100).toFixed(2);
    let RRRespondedTotal = responseRateData?.responseRateRespondedTotal;

    /* EMPLOYEE STATUS GRAPH DATA*/

    let ESGrandTotal = employeeStatusData?.employeeStatusGrandTotal;
    let ESCompleteTotal = employeeStatusData?.employeeStatusCompleteTotal;
    let ESIncompleteTotal = employeeStatusData?.employeeStatusIncompleteTotal;
    let ESNoResponseTotal = employeeStatusData?.employeeStatusNoResponseTotal;
    let ESCompletePercent = Number(employeeStatusData?.employeeStatusCompletePercent * 100).toFixed(2);
    let ESIncompletePercent = Number(employeeStatusData?.employeeStatusIncompletePercent * 100).toFixed(2);
    let ESNoResponsePercent = Number(employeeStatusData?.employeeStatusNoResponsePercent * 100).toFixed(2);

    /* DEPENDENT STATUS GRAPH DATA*/

    let DSGrandTotal = dependentStatusData?.dependentStatusGrandTotal;
    let DSPassedTotal = dependentStatusData?.dependentStatusPassedTotal;
    let DSSuspendTotal = dependentStatusData?.dependentStatusSuspendTotal;
    let DSIneligibleTotal = dependentStatusData?.dependentStatusIneligibleTotal;
    let DSReviewTotal = dependentStatusData?.dependentStatusReviewTotal;
    let DSIncompleteTotal = dependentStatusData?.dependentStatusIncompleteTotal;
    let DSNoResponseTotal = dependentStatusData?.dependentStatusNoResponseTotal;


    let DSPassedPercent = Number(dependentStatusData?.dependentStatusPassedPercent * 100).toFixed(2);
    let DsSuspendPercent = Number(dependentStatusData?.dependentStatusSuspendPercent * 100).toFixed(2);
    let DSIneligiblePercent = Number(dependentStatusData?.dependentStatusIneligiblePercent * 100).toFixed(2);
    let DSReviewPercent = Number(dependentStatusData?.dependentStatusReviewPercent * 100).toFixed(2);
    let DSIncompletePercent = Number(dependentStatusData?.dependentStatusIncompletePercent * 100).toFixed(2);
    let DSNoResponsePercent = Number(dependentStatusData?.dependentStatusNoResponsePercent * 100).toFixed(2);

    /* Incomplete Status Graph data */
    let ISInvalidDocReason = incompleteStatusData?.invalidDocumentReason;
    let ISInvalidDocPercent = Number(incompleteStatusData?.invalidDocPercent * 100).toFixed(2);
    let ISMissingDocReason = incompleteStatusData?.missingDocReason;
    let ISMissingDocPercent = Number(incompleteStatusData?.missingDocPercent * 100).toFixed(2);
    let ISDocNotLegibleReason = incompleteStatusData?.docNotLegibleReason;
    let ISDocNotLegiblePercent = Number(incompleteStatusData?.docNotLegiblePercent * 100).toFixed(2);
    let ISGrandTotal = incompleteStatusData?.total;

  


   



    const [reports, setReports] = useState([]);
    const initStatusTally = [{ id: 'No Response', value: 0, color: "hsl(85, 70%, 50%)" }, { id: 'Incomplete', value: 4000, color: "hsl(287, 70%, 50%)" }, { id: 'Complete', value: 100, color: "hsl(248, 70%, 50%)" }, { id: 'Ineligible', value: 500 }, { id: 'Suspend', value: 0, color: "hsl(142, 70%, 50%)" }]
    const [statusTally, setStatusTally] = useState(initStatusTally)
    const initEmailTally = [{ id: 'Yes', value: 928, color: "hsl(248, 70%, 50%)" }, { id: 'No', value: 207, color: "hsl(85, 70%, 50%)" }]
    const [emailTally, setEmailTally] = useState(initEmailTally)
    const initTotalTally = [{ type: "Employee", value: 0 }, { type: "Dependent", value: 0 }, { type: "Combined", value: 0 }]
    const [totalTally, setTotalTally] = useState(initTotalTally)
    const calendarDates = [{ value: 22, day: "2023-01-17" }, { value: 49, day: "2023-01-23" }, { value: 105, day: "2023-02-13" }, { value: 53, day: "2023-02-15" }, { value: 2, day: "2023-02-17" }, { value: 86, day: "2023-02-21" }];


 

    const [dependentReportData, setDependentReportData] = useState([]);
    
    const debouncefetchCSV = debounce(async () => {
        function fetchCSVFormattedDataAndPackageForDownload() {


            //const fetchDependentData = async (token) => {
            //    let response = null;
            //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatusData', {
            //        method: 'POST',
            //        mode: 'cors',
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'Authorization': 'Bearer ' + token,
            //        },
            //        body: JSON.stringify({ "auditId": id })
            //    })
            //        .then(res => { response = res.json() })
            //        .catch((error) => {
            //            console.log(error)
            //        })



            //    return response;

            //}

            //const fetchEmployeeData = async (token) => {
            //    let response = null;
            //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatusData', {
            //        method: 'POST',
            //        mode: 'cors',
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'Authorization': 'Bearer ' + token,

            //        },
            //        body: JSON.stringify({ "auditId": id })
            //    })
            //        .then(res => { response = res.json() })
            //        .catch((error) => {
            //            console.log(error)
            //        })

            //    return response;
            //}

            //const fetchAlertData = async (token) => {
            //    let response = null;
            //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
            //        method: 'POST',
            //        mode: 'cors',
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'Authorization': 'Bearer ' + token,
            //        },
            //        body: JSON.stringify({ "auditId": id })
            //    })
            //        .then(res => { response = res.json() })
            //        .catch((error) => {
            //            console.log(error)
            //        })

            //    return response;
            //}


            //const fetchIneligibleData = async (token) => {
            //    let response = null;
            //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleReportData', {
            //        method: 'POST',
            //        mode: 'cors',
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'Authorization': 'Bearer ' + token,
            //        },
            //        body: JSON.stringify({ "auditId": id })
            //    })
            //        .then(res => { response = res.json() })
            //        .catch((error) => {
            //            console.log(error)
            //        })
            //    return response;
            //}
            //const fetchIncompleteData = async (token) => {
            //    let response = null;
            //    await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteReportData', {
            //        method: 'POST',
            //        mode: 'cors',
            //        headers: {
            //            'Content-Type': 'application/json',
            //            'Authorization': 'Bearer ' + token,
            //        },
            //        body: JSON.stringify({ "auditId": id })
            //    })
            //        .then(res => { response = res.json() })
            //        .catch((error) => {
            //            console.log(error)
            //        })
            //    return response;
            //}


            //const fetchExportsInPromise = async () => {
            //    if (accounts.length > 0) {
            //        await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
            //            .then(result => {
            //                const fetchData = async () => {

            //                    await Promise.allSettled([
            //                        fetchEmployeeData(result.idToken),
            //                        fetchDependentData(result.idToken),
            //                        fetchAlertData(result.idToken),
            //                        fetchIneligibleData(result.idToken),
            //                        fetchIncompleteData(result.idToken),

            //                    ])
            //                        .then((result) => {
            //                            //result[0]?.value === employee data/family data
            //                            //result[1]?.value === dep data
            //                            //result[2]?.value === alert data
            //                            //result[3]?.value === ineligible data
            //                            //result[4]?.value === incomplete data
            //                            console.log("all settled reports res = ", result);
            //                            let isUndefined = false;
            //                            result?.map((fetchResult) => {
            //                                if (fetchResult?.value === undefined) {
            //                                    isUndefined = true;
            //                                }

            //                            });

            //                            const options = {
            //                                filename: `DependentStatusReport-${auditName}`,
            //                                fieldSeparator: ',',
            //                                quoteStrings: '"',
            //                                decimalSeparator: '.',
            //                                showLabels: true,
            //                                showTitle: false,
            //                                useTextFile: false,
            //                                useBom: true,
            //                                useKeysAsHeaders: true,
            //                                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //                            };





            //                            const options2 = {
            //                                filename: `FamilyStatusReport-${auditName}`,
            //                                fieldSeparator: ',',
            //                                quoteStrings: '"',
            //                                decimalSeparator: '.',
            //                                showLabels: true,
            //                                showTitle: false,
            //                                useTextFile: false,
            //                                useBom: true,
            //                                useKeysAsHeaders: true,
            //                                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //                            };



            //                            const options3 = {
            //                                filename: `AlertReport-${auditName}`,
            //                                fieldSeparator: ',',
            //                                quoteStrings: '"',
            //                                decimalSeparator: '.',
            //                                showLabels: true,
            //                                showTitle: false,
            //                                useTextFile: false,
            //                                useBom: true,
            //                                useKeysAsHeaders: true,
            //                                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //                            };





            //                            const options4 = {
            //                                filename: `IneligibleReport-${auditName}`,
            //                                fieldSeparator: ',',
            //                                quoteStrings: '"',
            //                                decimalSeparator: '.',
            //                                showLabels: true,
            //                                showTitle: false,
            //                                useTextFile: false,
            //                                useBom: true,
            //                                useKeysAsHeaders: true,
            //                                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //                            };




            //                            const options5 = {
            //                                filename: `IncompleteReport-${auditName}`,
            //                                fieldSeparator: ',',
            //                                quoteStrings: '"',
            //                                decimalSeparator: '.',
            //                                showLabels: true,
            //                                showTitle: false,
            //                                useTextFile: false,
            //                                useBom: true,
            //                                useKeysAsHeaders: true,
            //                                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //                            };

            //                            if (isUndefined !== true) {
            //                                const csvExporter5 = new ExportToCsv(options5);
            //                                const csvExporter4 = new ExportToCsv(options4);
            //                                const csvExporter3 = new ExportToCsv(options3);
            //                                const csvExporter2 = new ExportToCsv(options2);
            //                                const csvExporter = new ExportToCsv(options);
            //                                csvExporter2.generateCsv(result[0]?.value);
            //                                csvExporter3.generateCsv(result[2]?.value);
            //                                csvExporter.generateCsv(result[1]?.value);
            //                                csvExporter4.generateCsv(result[3]?.value);
            //                                csvExporter5.generateCsv(result[4]?.value);
            //                            }

            //                            if (isUndefined === true) {
            //                                toast.error("Unable to export documents. Please refresh and try again.", {
            //                                    position: "bottom-center",
            //                                    theme: "colored",
            //                                    autoClose: false,
            //                                    closeOnClick: true,
            //                                    toastId: "failedToFetch"
            //                                });
            //                            }

            //                        });


            //                }
            //                fetchData();
            //            }).catch((error) => {
            //                console.log(error) //token expired/is not valid => user is redirected to login page
            //                if (error instanceof InteractionRequiredAuthError) {
            //                    instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
            //                }
            //            });
            //    }
            //}

            //fetchExportsInPromise();


            //export to CSV
            const options = {
                filename: `DependentStatusReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };





            const options2 = {
                filename: `FamilyStatusReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };



            const options3 = {
                filename: `AlertReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };





            const options4 = {
                filename: `IneligibleReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };




            const options5 = {
                filename: `IncompleteReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

           
                const csvExporter5 = new ExportToCsv(options5);
                const csvExporter4 = new ExportToCsv(options4);
                const csvExporter3 = new ExportToCsv(options3);
                const csvExporter2 = new ExportToCsv(options2);
                const csvExporter = new ExportToCsv(options);
                csvExporter2.generateCsv(familyReport); //family
                csvExporter3.generateCsv(alertReport); //alert
                csvExporter.generateCsv(dependentReport); //dependent
                csvExporter4.generateCsv(ineligibleReport); //ineligible
                csvExporter5.generateCsv(incompleteReport); //incomplete
          

        }
        fetchCSVFormattedDataAndPackageForDownload();
    }, 10000, { 'leading': true, 'trailing': false});

    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >
                
                {/*  DESKTOP PAGE TITLES/HEADINGS */}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">

                            {promiseIsLoading === false ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Dashboard</Typography>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">
                           
                            {promiseIsLoading === false ? (<>
                                <Tooltip title="Export Graph Data as a CSV">
                                    <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>
                                    <Button variant="contained"
                                        disableElevation sx={{
                                            bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                            ':hover': {
                                                bgcolor: '#dddddd', // theme.palette.primary.main
                                                color: '#ffffff',
                                                boxShadow: 0,
                                                },
                                            }} onClick={() => { debouncefetchCSV(); }}
                                        style={{ textTransform: 'none' }}
                                    >
                                        <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                    </Button>
                                    </Badge>
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                    </div>
                </Container >
         

                {/* Project Cards - DESKTOP  */}
                <Container sx={{ ml: 0, }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                       

                    </Grid>
                </Container>

                <Container sx={{ ml: 0 }}>
                    <Grid container spacing={3} sx={{ mt: 0.5, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs="auto" >

                            {responseRateData != undefined ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphData={null}
                                    graphComponent={





                                        <PieGraph
                                            graphValueA={RRRespondedTotal}
                                            graphValueB={RRNoResponseTotal}
                                            graphValueC={0}
                                            graphLabelValueA={"Responded"}
                                            graphLabelValueB={"No Response"}
                                            metricSubTitle={'Response Rate'}
                                            alternateCenterMetricPercent={RRRespondedPercent}
                                            graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                        />





                                    }
                                    graphLegendComponent={
                                        responseRateData != undefined ? (
                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Responded",
                                                            metricValue: RRRespondedTotal,
                                                            metricPercentage: RRRespondedPercent,
                                                        },
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: RRNoResponseTotal,
                                                            metricPercentage: RRNoResponsePercent,
                                                        },
                                                    ]
                                                }
                                            />) : (<></>)
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphLink={null}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />
                                </>)}

                        </Grid>
                        <Grid item xs="auto" >
                            {employeeStatusData != undefined ? (
                                <GraphCard
                                    graphTableData={familyReport}
                                    graphLink={null}
                                    graphName={"Family Status"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphData={[
                                        {
                                            metricTitle: "Complete",
                                            metricValue: ESCompleteTotal,
                                            metricPercentage: ESCompletePercent,
                                        },
                                        {
                                            metricTitle: "No Response",
                                            metricValue: ESNoResponseTotal,
                                            metricPercentage: ESNoResponsePercent,
                                        },
                                        {
                                            metricTitle: "Incomplete",
                                            metricValue: ESIncompleteTotal,
                                            metricPercentage: ESIncompletePercent,
                                        },
                                    ]}
                                    graphComponent={

                                        <PieGraph
                                            graphValueA={ESCompleteTotal}
                                            graphValueB={ESNoResponseTotal}
                                            graphValueC={ESIncompleteTotal}
                                            graphLabelValueA={"Complete"}
                                            graphLabelValueB={"No Response"}
                                            graphLabelValueC={"Incomplete"}
                                            metricSubTitle={'Families'}
                                            graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                        />
                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Complete",
                                                        metricValue: ESCompleteTotal,
                                                        metricPercentage: ESCompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: ESNoResponseTotal,
                                                        metricPercentage: ESNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: ESIncompleteTotal,
                                                        metricPercentage: ESIncompletePercent,
                                                    },
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphLink={null}
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />

                                </>)}
                           
                        </Grid>

                        <Grid item xs="auto">
                            {dependentStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Dependent Status"}
                                    graphLink={null}
                                    graphTableData={dependentReport}
                                    graphData={[
                                        {
                                            metricTitle: "No Response",
                                            metricValue: DSNoResponseTotal,
                                            metricPercentage: DSNoResponsePercent,
                                        },
                                        {
                                            metricTitle: "Incomplete",
                                            metricValue: DSIncompleteTotal,
                                            metricPercentage: DSIncompletePercent,
                                        },
                                        {
                                            metricTitle: "Review",
                                            metricValue: DSReviewTotal,
                                            metricPercentage: DSReviewPercent,
                                        },
                                        {
                                            metricTitle: "Passed",
                                            metricValue: DSPassedTotal,
                                            metricPercentage: DSPassedPercent,
                                        },
                                        {
                                            metricTitle: "Suspend",
                                            metricValue: DSSuspendTotal,
                                            metricPercentage: DsSuspendPercent,
                                        },
                                        {
                                            metricTitle: "Ineligible",
                                            metricValue: DSIneligibleTotal,
                                            metricPercentage: DSIneligiblePercent,
                                        },

                                    ]}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphLegendComponent={

                                        <PercentageBarChart
                                            percentageBarChartData={
                                                [
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: DSNoResponseTotal,
                                                        metricPercentage: DSNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: DSIncompleteTotal,
                                                        metricPercentage: DSIncompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "Review",
                                                        metricValue: DSReviewTotal,
                                                        metricPercentage: DSReviewPercent,
                                                    },
                                                    {
                                                        metricTitle: "Passed",
                                                        metricValue: DSPassedTotal,
                                                        metricPercentage: DSPassedPercent,
                                                    },
                                                    {
                                                        metricTitle: "Suspend",
                                                        metricValue: DSSuspendTotal,
                                                        metricPercentage: DsSuspendPercent,
                                                    },
                                                    {
                                                        metricTitle: "Ineligible",
                                                        metricValue: DSIneligibleTotal,
                                                        metricPercentage: DSIneligiblePercent,
                                                    },

                                                ]
                                            }
                                        />}
                                />) : (<>
                                    <GraphCard
                                        graphLink={null}
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)}
                          
                        </Grid>
                        <>
                        {showIneligibleChart ? (
                        <>
                        {ineligibleStatusData != undefined ? (<>
                            {extendIneligibleGraphContainer ? (
                                <Grid item xs={7.7}>
                                                <IneligibleExtendedGraphCard
                                                    graphTableData={ineligibleReport}
                                                    graphName={"Ineligible Reason"}
                                                    graphLink={null}
                                            desiredCardWidth={715}
                                            desiredCardHeight={400}
                                            graphLegendComponent={
                                                <Grid container columnSpacing={{ xs: 2 }} justifyContent="center">
                                                    <Grid item sx={2}>
                                                        <IneligibleReasonBarChart
                                                            percentageBarChartData={
                                                                firstIneligibleList
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sx={2}>
                                                        <IneligibleReasonBarChart
                                                            percentageBarChartData={
                                                                secIneligibleList
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                            }
                                        />
                                </Grid> 
                            ) : (
                                    <Grid item xs="auto">
                                                    <GraphCard
                                                        graphLink={null}
                                                        graphTableData={ineligibleReport}
                                                        graphData={finalIneligibleList}
                                                graphName={"Ineligible Reason"}
                                                desiredCardWidth={340}
                                                desiredCardHeight={400}
                                                graphLegendComponent={

                                                    <IneligibleReasonBarChart
                                                        percentageBarChartData={ //needs to be dynamic list

                                                            finalIneligibleList


                                                        }
                                                    />}
                                            />
                                    </Grid>
                            
                            
                            )}
                        </>) : (<>
                        <Grid item xs="auto">
                                                <GraphCard
                                                    graphLink={null}
                                graphName={""}
                                desiredCardWidth={340}
                                desiredCardHeight={400}
                                graphComponent={

                                <div className="row-no-left-margin">
                                    <div className="center">
                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                            <Skeleton height={360} width={308} />
                                        </SkeletonTheme>
                                    </div>
                                </div>

                                }
                                graphLegendComponent={
                                 <></>
                                }
                            />               
                        </Grid>
                        </>)}
                        </>
                        ) : (<></>)}
                        </>
                        <>

                        {showIncompleteChart ? (
                            <Grid item xs="auto" >
                                {incompleteStatusData != undefined ? (
                                        <GraphCard
                                            graphLink={null}
                                            graphTableData={incompleteReport}
                                            graphData={[
                                                {
                                                    metricTitle: "Missing Document(s)",
                                                    metricValue: ISMissingDocReason,
                                                    metricPercentage: ISMissingDocPercent
                                                },
                                                {
                                                    metricTitle: "Invalid Documents",
                                                    metricValue: ISInvalidDocReason,
                                                    metricPercentage: ISInvalidDocPercent
                                                },
                                                {
                                                    metricTitle: "Document Not Legible",
                                                    metricValue: ISDocNotLegibleReason,
                                                    metricPercentage: ISDocNotLegiblePercent
                                                },
                                            ]}
                                        graphName={"Incomplete Reason"}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <PieGraph
                                                graphValueA={ISMissingDocReason}
                                                graphValueB={ISInvalidDocReason}
                                                graphValueC={ISDocNotLegibleReason}
                                                graphLabelValueA={"Missing Document(s)"}
                                                graphLabelValueB={"Invalid Documents"}
                                                graphLabelValueC={"Document Not Legible"}
                                                metricSubTitle={"Incomplete"}
                                                graphTitle={"Incomplete Status Graph"}
                                            />
                                        }
                                        graphLegendComponent={

                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Missing Document(s)",
                                                            metricValue: ISMissingDocReason,
                                                            metricPercentage: ISMissingDocPercent
                                                        },
                                                        {
                                                            metricTitle: "Invalid Documents",
                                                            metricValue: ISInvalidDocReason,
                                                            metricPercentage: ISInvalidDocPercent
                                                        },
                                                        {
                                                            metricTitle: "Document Not Legible",
                                                            metricValue: ISDocNotLegibleReason,
                                                            metricPercentage: ISDocNotLegiblePercent
                                                        },
                                                    ]
                                                }
                                            />
                                        }
                                    />) : (<>
                                            <GraphCard
                                                graphLink={null}
                                            graphName={""}
                                            desiredCardWidth={340}
                                            desiredCardHeight={400}
                                            graphComponent={

                                                <div className="row-no-left-margin">
                                                    <div className="center">
                                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                            <Skeleton height={360} width={308} />
                                                        </SkeletonTheme>
                                                    </div>
                                                </div>

                                            }
                                            graphLegendComponent={
                                                <></>
                                            }
                                        />

                                    </>)}

                            </Grid>
                        ) : (<></>)}
                        </>
                      
                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                      
                    </Grid>
                </Container>
                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>
            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/* //MOBILE LAYOUT/COMPONENTS*/}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">
                          
                            {promiseIsLoading === false ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Dashboard</Typography>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">
                           
                            {promiseIsLoading === false ? (<>
                                <Tooltip title="Export Graph Data as a CSV">
                                    <Badge badgeContent={alertNoteCount} color="error" invisible={reportBadge}>
                                        <Button variant="contained"
                                            disableElevation sx={{
                                                bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                                ':hover': {
                                                    bgcolor: '#dddddd', // theme.palette.primary.main
                                                    color: '#ffffff',
                                                    boxShadow: 0,
                                                },
                              
                                             
                                            }}
                                            style={{ textTransform: 'none' }}
                                            onClick={() => { debouncefetchCSV(); }} >
                                        <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                        </Button>
                                        </Badge>
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                    </div>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 5, mr: 1, display: { xs: 'block', md: 'none' } }}>
                       
                    </Grid>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 2, mr: 1, display: { xs: 'block', md: 'none' } }}>
                        <Grid item xs={12} sx={{ pl:0 }}>
                            {responseRateData != undefined ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphComponent={
                                        <div className="row-no-margin">
                                            <div className="center">
                                                <PieGraph
                                                    mobileGraph={true}
                                                    graphValueA={RRRespondedTotal}
                                                    graphValueB={RRNoResponseTotal}
                                                    graphValueC={0}
                                                    graphLabelValueA={"Responded"}
                                                    graphLabelValueB={"No Response"}
                                                    metricSubTitle={'Response Rate'}
                                                    alternateCenterMetricPercent={RRRespondedPercent}
                                                    graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                                />
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Responded",
                                                        metricValue: RRRespondedTotal,
                                                        metricPercentage: RRRespondedPercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: RRNoResponseTotal,
                                                        metricPercentage: RRNoResponsePercent,
                                                    },
                                                   
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>

                                    <GraphCard
                                        graphName={""}
                                        graphLink={null}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={
                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />



                                </>)}
                        </Grid>
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {employeeStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Family Status"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphTableData={familyReport}
                                    graphData={[
                                        {
                                            metricTitle: "Complete",
                                            metricValue: ESCompleteTotal,
                                            metricPercentage: ESCompletePercent,
                                        },
                                        {
                                            metricTitle: "No Response",
                                            metricValue: ESNoResponseTotal,
                                            metricPercentage: ESNoResponsePercent,
                                        },
                                        {
                                            metricTitle: "Incomplete",
                                            metricValue: ESIncompleteTotal,
                                            metricPercentage: ESIncompletePercent,
                                        },
                                    ]}
                                    graphLink={null}
                                    graphComponent={
                                        <div className="row-no-margin">
                                            <div className="center">
                                                <PieGraph
                                                    mobileGraph={true}
                                                    graphValueA={ESCompleteTotal}
                                                    graphValueB={ESNoResponseTotal}
                                                    graphValueC={ESIncompleteTotal}
                                                    graphLabelValueA={"Complete"}
                                                    graphLabelValueB={"No Response"}
                                                    graphLabelValueC={"Incomplete"}
                                                    metricSubTitle={'Families'}
                                                    graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                                />
                                            </div>
                                        </div>
                                    }
                                    graphLegendComponent={
                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Complete",
                                                        metricValue: ESCompleteTotal,
                                                        metricPercentage: ESCompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: ESNoResponseTotal,
                                                        metricPercentage: ESNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: ESIncompleteTotal,
                                                        metricPercentage: ESIncompletePercent,
                                                    },
                                                  
                                                ]
                                            }
                                        />

                                    }
                                />) : (<>

                                    <GraphCard
                                        graphName={""}
                                        graphLink={null}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)}
                        </Grid>


                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {dependentStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Dependent Status"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphTableData={dependentReport}
                                    graphLegendComponent={
                                        <PercentageBarChart
                                            percentageBarChartData={
                                                [
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: DSNoResponseTotal,
                                                        metricPercentage: DSNoResponsePercent,
                                                    },
                                                    {
                                                        metricTitle: "Incomplete",
                                                        metricValue: DSIncompleteTotal,
                                                        metricPercentage: DSIncompletePercent,
                                                    },
                                                    {
                                                        metricTitle: "Review",
                                                        metricValue: DSReviewTotal,
                                                        metricPercentage: DSReviewPercent,
                                                    },
                                                    {
                                                        metricTitle: "Passed",
                                                        metricValue: DSPassedTotal,
                                                        metricPercentage: DSPassedPercent,
                                                    },
                                                    {
                                                        metricTitle: "Suspend",
                                                        metricValue: DSSuspendTotal,
                                                        metricPercentage: DsSuspendPercent,
                                                    },
                                                    {
                                                        metricTitle: "Ineligible",
                                                        metricValue: DSIneligibleTotal,
                                                        metricPercentage: DSIneligiblePercent,
                                                    },

                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphLink={null}
                                        graphComponent={
                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </>)} 
                        </Grid>

                        <>
                        {showIneligibleChart ? (
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {ineligibleStatusData != undefined ? (
                                <GraphCard
                                            graphName={"Ineligible Reason"}
                                            graphLink={null}
                                            graphTableData={ineligibleReport}
                                    desiredCardWidth={"84VW"}
                                    desiredCardHeight={400}
                                    graphLegendComponent={

                                        <IneligibleReasonBarChart
                                            percentageBarChartData={ //needs to be dynamic list
                                               finalIneligibleList
                                            }
                                        />}
                                />) : (<>
                                    <GraphCard
                                                graphName={""}
                                                graphLink={null}
                                        desiredCardWidth={"84VW"}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={



                                            <></>
                                        }
                                    />
                                </>)}

                            </Grid>
                        
                            ) : (<></>)}
                        </>
                        <>
                        {showIncompleteChart ? (

                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {incompleteStatusData != undefined ? (
                                <GraphCard
                                    graphName={"Incomplete Reason"}
                                    desiredCardWidth={"84VW"}
                                    desiredCardHeight={400}
                                            graphLink={null}
                                            graphTableData={incompleteReport}
                                            graphData={[
                                                {
                                                    metricTitle: "Missing Document(s)",
                                                    metricValue: ISMissingDocReason,
                                                    metricPercentage: ISMissingDocPercent
                                                },
                                                {
                                                    metricTitle: "Invalid Documents",
                                                    metricValue: ISInvalidDocReason,
                                                    metricPercentage: ISInvalidDocPercent
                                                },
                                                {
                                                    metricTitle: "Document Not Legible",
                                                    metricValue: ISDocNotLegibleReason,
                                                    metricPercentage: ISDocNotLegiblePercent
                                                },
                                            ]}
                                    graphComponent={

                                        <PieGraph
                                            graphValueA={ISMissingDocReason}
                                            graphValueB={ISInvalidDocReason}
                                            graphValueC={ISDocNotLegibleReason}
                                            graphLabelValueA={"Missing Document(s)"}
                                            graphLabelValueB={"Invalid Documents"}
                                            graphLabelValueC={"Document Not Legible"}
                                            metricSubTitle={"Incomplete"}
                                            graphTitle={"Incomplete Status Graph"}
                                        />
                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Missing Document(s)",
                                                        metricValue: ISMissingDocReason,
                                                        metricPercentage: ISMissingDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Invalid Documents",
                                                        metricValue: ISInvalidDocReason,
                                                        metricPercentage: ISInvalidDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Document Not Legible",
                                                        metricValue: ISDocNotLegibleReason,
                                                        metricPercentage: ISDocNotLegiblePercent
                                                    },
                                                ]
                                            }
                                        />
                                    }
                                />) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={"84VW"}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />

                                </>)}

                        </Grid>
                        ) : (<></>)}
                        </>

                    </Grid>
               
                </Container>
            </Box>
            <ToastContainer
                limit={1}
                rtl={false}

            />
        </>
    )
}

export default Project;
