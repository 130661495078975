

import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
import { GraphDetailsContext, SidebarContext } from "../App";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, RolesContext } from "../App";
import useFetch from "../hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Button from '@mui/material/Button';
import { IoMdArrowRoundBack } from "react-icons/io";

import { TbWorldSearch } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";


import FamilyDetailsEmployeeCard from '../components/cards/FamilyDetailsEmployeeCard';
import Divider from '@mui/material/Divider';


import FamilyDetailsMembersTable from '../components/tables/FamilyDetailsMembersTable';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';

import FamilyDetailsFilesUploadedTable from '../components/tables/FamilyDetailsFilesUploadedTable';

import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";

import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";



import GraphDetailsTable from '../components/tables/GraphDetailsTable';
import GraphDetailsTableMobile from "../components/tables/GraphDetailsTableMobile";

import GraphCardWide from '../components/cards/GraphCardWide';
import GraphCardWideMobile from '../components/cards/GraphCardWideMobile';
import GraphCardMobile from '../components/cards/GraphCardMobile';
import GraphLegendCardMobile from '../components/cards/GraphLegendCardMobile';







function GraphDetails({ onChange, children }) {

    /* const { rowSelectedMemberData } = useContext(EmployeeSearchContext)*/

    /* console.log("PASSED IN ROW SELECTED MEMBER DATA: ",  rowSelectedMemberData  )*/

    const {

        setSidebarTitle,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        setSidebarActions
    } = useContext(SidebarContext);

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData,
        openMobileGraphDetails
    } = useContext(GraphDetailsContext);

    const [hasGraphContextData, setHasGraphContextData] = useState(false)

    const [graphContextData, setGraphContextData] = useState(graphComponentData?.length !== 0 ? graphComponentData : [])

    const [hasGraphContextTableData, setHasGraphContextTableData] = useState(false)

    const [graphContextTableData, setGraphContextTableData] = useState(graphTableComponentData?.length !== 0 ? graphTableComponentData : [])

    function handleUpdateGraphContextData(update) {
        setGraphContextData(update)
    }

    const { id, auditName, auditFamilyId, employeeId } = useParams();
    const { instance, accounts, inProgress } = useMsal();

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    //client
   
    const [users, setUsers] = useState([]);
    //const users = useRef({});
    const [haveUserData, setHaveUserData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [status, setStatus] = useState(false);
   
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    //const [fetchNewData, setFetchNewData] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    //GRAPH NAME FOR SWITCH CASE

    const { state } = useLocation();

    let graphName = state?.graph_title;

    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    useEffect(() => {

        setSidebarTitle(`${auditName}`)
        setSidebarActiveClientID(`${id}`)
    }, [auditName, id])



    //toggle visible modal for adding user when on 'add user' button click
    const toggleVisible = () => {
        setVisible(!visible)
    }

    const toggleVisible1 = () => {
        setVisible1(!visible1)
    }

    const toggleVisible2 = () => {
        setVisible2(!visible2)
    }

    const toggleLoading = () => {
        setLoading(!loading)
    }

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)

            setIsLoading(true);

        }

    }, [isLoading]);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        //{
        //    id: 3,
        //    label: "Processing",
        //    path: "processing",
        //    icon: <AssignmentTurnedInIcon />,
        //    isActive: false,
        //    style: '',
        //    isNested: false
        //}
    ]);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);
    const [validRoute, setValidRoute] = useState(null);
    const [reports, setReports] = useState([]);

    useEffect(() => {

        console.log("valid Route --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            setIsLoading(false);

        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: true,
                //    style: '',
                //    isNested: false
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                //{
                //    id: 2,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: true,
                //    style: '',
                //    isNested: true
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 5,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles, validRoute]);



    //useEffect(() => {
    //    if (accounts?.length > 0) { //if account exists authorize user
    //        const fetchUserData = async () => {
    //            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
    //                .then(result => {
    //                    const fetchData = async () => {
    //                        await fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/GetUsers', {
    //                            method: 'POST',
    //                            mode: 'cors',
    //                            headers: {
    //                                'Content-Type': 'application/json',
    //                                'Authorization': 'Bearer ' + result.idToken,
    //                            },
    //                            body: JSON.stringify({ "auditId": id })
    //                        })
    //                            .then(res => res.json())
    //                            .then((response) => {
    //                                console.log(response)
    //                                setHaveUserData(true);
    //                                setUsers(response?.b2CUsers);

    //                                //users.current = [...response];
    //                            }).catch((error) => {
    //                                console.log(error)
    //                            })


    //                    }

    //                    fetchData();

    //                }).catch((error) => {
    //                    console.log(error) //token expired/is not valid => user is redirected to login page
    //                    if (error instanceof InteractionRequiredAuthError) {
    //                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
    //                    }

    //                });

    //        }
    //        fetchUserData();
    //    }

    //}, [haveUserData, setHaveUserData])

   

   

       



    const handleNavigateBackToProject = (params) => {

        /* console.log(params);*/


        navigate(`/report/${String(id)}/${auditName}`);
        

    };

    let detailGraphComponent = null;
    let detailGraphTableComponent = null;
    let detailGraphComponentMobile = null;
    let detailGraphComponentMobile2 = null;
    let detailGraphTableComponentMobile = null;
   /* let detailGraphTableInputComponents = null;*/

 

    if (graphName == 'Family Status' && graphContextData !== null && graphContextTableData !== null) {
        /* detailGraphTableInputComponents = {};*/
        detailGraphComponent = <GraphCardWide
            graphLink={null}
            graphName={null}
            desiredCardWidth={1000}
            desiredCardHeight={60}
            graphData={graphContextData}
            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Complete"}
                    graphLabelValueB={"No Response"}
                    graphLabelValueC={"Incomplete"}
                    metricSubTitle={'Families'}
                    graphTitle={`Employee Status Graph - ${graphContextData[0]?.metricValue} Complete employees out of ${graphContextData[0]?.metricValue + graphContextData[1]?.metricValue + graphContextData[2]?.metricValue} Total Employees`}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Complete",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;
        detailGraphTableComponent = <GraphDetailsTable
            tableData={graphContextTableData}
            tableTitle={'Family Status'}
            tableColumns={[
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "First Name",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "status",
                    "headerName": "Status",
                    "width": 250,
                    "editable": false
                }

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={graphContextTableData}
            tableTitle={'Family Status'}
            tableColumns={[
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "First Name",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "status",
                    "headerName": "Status",
                    "width": 250,
                    "editable": false
                }

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
        detailGraphComponentMobile = <GraphCardWideMobile
            graphLink={null}
            graphName={null}
            desiredCardWidth={360}
            desiredCardHeight={170}
            graphData={graphContextData}

            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Complete"}
                    graphLabelValueB={"No Response"}
                    graphLabelValueC={"Incomplete"}
                    metricSubTitle={'Families'}
                    graphTitle={`Employee Status Graph - ${graphContextData[0]?.metricValue} Complete employees out of ${graphContextData[0]?.metricValue + graphContextData[1]?.metricValue + graphContextData[2]?.metricValue} Total Employees`}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Complete",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;



    } else if (graphName == 'Incomplete Reason' && graphContextData !== null && graphContextTableData !== null) {
        detailGraphComponent = <GraphCardWide
            graphLink={null}
            graphName={null}
            desiredCardWidth={1000}
            desiredCardHeight={60}
            graphData={graphContextData}
            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Missing Document(s)"}
                    graphLabelValueB={"Invalid Documents"}
                    graphLabelValueC={"Document Not Legible"}
                    metricSubTitle={"Incomplete"}
                    graphTitle={"Incomplete Status Graph"}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Missing Document(s)",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Invalid Documents",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Document Not Legible",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;
        detailGraphTableComponent = <GraphDetailsTable
            tableData={graphContextTableData}
            tableTitle={'Incomplete Reason'}
            tableColumns={[
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "employeeFirstName",
                    "headerName": "Employee First Name",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Employee Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },

                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 150,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={graphContextTableData}
            tableTitle={'Incomplete Reason'}
            tableColumns={[
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "employeeFirstName",
                    "headerName": "Employee First Name",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Employee Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },

                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 150,
                    "editable": false
                },

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
        detailGraphComponentMobile = <GraphCardWideMobile
            graphLink={null}
            graphName={null}
            desiredCardWidth={360}
            desiredCardHeight={170}
            graphData={graphContextData}

            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Missing Document(s)"}
                    graphLabelValueB={"Invalid Documents"}
                    graphLabelValueC={"Document Not Legible"}
                    metricSubTitle={"Incomplete"}
                    graphTitle={"Incomplete Status Graph"}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Missing Document(s)",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Invalid Documents",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Document Not Legible",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;

    } else if (graphName == 'Dependent Status' && graphContextData !== null && graphContextTableData !== null) {
        detailGraphTableComponent = <GraphDetailsTable
            tableData={graphContextTableData}
            tableTitle={'Dependent Status'}
            tableColumns={[

                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Employee Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Employee First Name",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 180
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "relationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 120,
                    "editable": false
                },




                {
                    "field": "status",
                    "headerName": "Dependent Status",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={graphContextTableData}
            tableTitle={'Dependent Status'}
            tableColumns={[
               
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Employee Last Name",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Employee First Name",
                    "width": 120,
                    "editable": false
                },
               
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 180
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 120,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 120,
                    "editable": false
                },
               
               
               
              
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 120,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 250,
                    "editable": false
                },

              

              

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
    } 


  


    

   
      






















    return (
        <>

            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >


                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>

                    <div className="row-no-left-margin">
                        <Button variant="text"
                            sx={{ mt: 0, ml: '-0.5VW' }}
                            onClick={() => handleNavigateBackToProject()}
                        > <IoMdArrowRoundBack />Back to Project</Button>
                        <div className="row-left">




                        </div>


                    </div>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>{graphName} Graph Details </Typography>
                   {/* <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 0, mb: 1, color: '#737373' }}>Graph Details {mgraphNAME}</Typography>*/}

                   











                </Container >

                <Container sx={{ ml: 0 }}>
                    <Grid container spacing={3} sx={{ mt: '-1VH', mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>


                        <Grid item xs="auto" >
                            {graphComponentData?.length !== 0 ? (<>

                                {detailGraphComponent}

                            </>) : (<>



                            </>)}
                        </Grid>
                    </Grid>
                    {/*<Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>*/}

                    {/*</Grid>*/}
                   
                </Container>
                {graphContextTableData?.length !== 0 ? (<>

                    {detailGraphTableComponent}

                </>) : (<>



                </>)}
             

                {/* DESKTOP TABLE*/}

               

                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                   
                  
                   
                </Container>

               

                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                   

                </Container>

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>






            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}


                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                {openMobileGraphDetails ? (<></>) : (<>
                    <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                        <div className="row-no-left-margin">
                            <div className="row-left">


                                <Button variant="text"
                                    sx={{ mt: 0, ml: '-1VW' }}
                                    onClick={() => handleNavigateBackToProject()}
                                > <IoMdArrowRoundBack />Back to Project</Button>

                            </div>


                        </div>
                        <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, mb: 3 }}>{graphName} Graph Details</Typography>

                        <Container sx={{ ml: 0, mt: 2 }}>
                            <Grid container spacing={1} sx={{ mt: '-2VH', mb: 2, display: { xs: 'flex', md: 'none', xl: 'none' } }}>



                                {graphComponentData?.length !== 0 ? (<>
                                    <Grid item xs="auto" sx={{ml: '-2.5VW'}} >
                                        {detailGraphComponentMobile}
                                    </Grid>
                                    {detailGraphComponentMobile2 !== null ? (<>
                                        <Grid item xs="auto" sx={{
                                            ml: '44VW',
                                            mt: '-21VH'
   }} >
                                            {detailGraphComponentMobile2}
                                        </Grid>
                                    </>) : (<></>)}

                                </>) : (<>



                                </>)}


                            </Grid>
                            {/*<Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>*/}

                            {/*</Grid>*/}

                        </Container>


                    </Container >
                </>)}

            

                {graphContextTableData?.length !== 0 ? (<>

                    {detailGraphTableComponentMobile}

                </>) : (<>



                </>)}

               



            </Box>

        </>

    )
}

export default GraphDetails;

