
import React, { useState, useContext } from 'react';


import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { format, formatISO } from 'date-fns'

import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';


function FamilyDetailsMembersTable({ tableData, desiredWidth, desiredHeight }) {

    if (desiredWidth == undefined || desiredWidth == null) {

    }

    //let mock_dependent_findings = [

    //    { rowID: 0, auditFindingTitle: "Audit Finding 1", auditFindingText: "As part of this verification, a certified marriage certificate (registered to County/State) is required. A marriage license and church document are not acceptable." },
    //    { rowID: 1, auditFindingTitle: "Audit Finding 2", auditFindingText: "The joint document submitted does not meet the requirements of the audit. A current joint document is required to verify the relationship to this dependent. The joint document must be addressed to both the employee and the spouse and is dated within the past 90 days." },
    //    { rowID: 2, auditFindingTitle: "Audit Finding 3", auditFindingText: "As part of this verification, a certified marriage certificate (registered to County/State) is required. A marriage license and church document are not acceptable." },
    //    { rowID: 3, auditFindingTitle: "Audit Finding 4", auditFindingText: "The joint document submitted does not meet the requirements of the audit. A current joint document is required to verify the relationship to this dependent. The joint document must be addressed to both the employee and the spouse and is dated within the past 90 days." },
    //    { rowID: 4, auditFindingTitle: "Audit Finding 5", auditFindingText: "As part of this verification, a certified marriage certificate (registered to County/State) is required. A marriage license and church document are not acceptable." },
    //    { rowID: 5, auditFindingTitle: "Audit Finding 6", auditFindingText: "The joint document submitted does not meet the requirements of the audit. A current joint document is required to verify the relationship to this dependent. The joint document must be addressed to both the employee and the spouse and is dated within the past 90 days." },
    //    { rowID: 6, auditFindingTitle: "Audit Finding 7", auditFindingText: "As part of this verification, a certified marriage certificate (registered to County/State) is required. A marriage license and church document are not acceptable." },
    //    { rowID: 7, auditFindingTitle: "Audit Finding 8", auditFindingText: "The joint document submitted does not meet the requirements of the audit. A current joint document is required to verify the relationship to this dependent. The joint document must be addressed to both the employee and the spouse and is dated within the past 90 days." },
    //    { rowID: 8, auditFindingTitle: "Audit Finding 9", auditFindingText: "As part of this verification, a certified marriage certificate (registered to County/State) is required. A marriage license and church document are not acceptable." },

    //]

    //from mui documentation

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (name !== undefined) {

            return {
                sx: {
                    //bgcolor: '#737373',
                    bgcolor: '#23549F',
                    color: '#FFFFFF',
                    fontSize: 12
                },
                children: `${name.substring(0, 3)}`,
            };
        }
    }

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    function renderRelationship(row) {
        console.log("ROW -----", row);
        if (row === "E") {
            return 'Employee'
        }
        else if (row === "S ") {
            return 'Spouse'
        } else if (row === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row}`
        }
    }

    function renderMemberStatus(dataText) {
        if (dataText == "Passed") {
            return (<Chip sx={{ bgcolor:  '#BBF7D0', color: '#166534' }} label={dataText} />)
        } else if (dataText == "Incomplete") {
            return (<Chip sx={{ bgcolor: '#FDE68A', color: '#92400E' }} label={dataText} />)
        } else if (dataText == "Ineligible") {
            return (<Chip sx={{ bgcolor: '#FECACA', color:  '#991B1B', }} label={dataText} />)
        } else if (dataText == null) {
            return (<Chip sx={{ bgcolor: '#FECACA', color: '#991B1B', }} label={'DB: NULL'} />)
        } else {
            return (<Chip label={dataText} />)
        }
    }



    const rows = tableData;
    const navigate = useNavigate();

    const columns = [
       
        {
            "field": "dependent_first_name",
            "headerName": "First Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "dependent_last_name",
            "headerName": "Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "dependent_relationship",
            "headerName": "Relationship",
            "width": 140,
            "editable": false,
            renderCell: (params) =>
                renderRelationship(params.row?.dependent_relationship)
        },
        {
            "field": "dependent_date_of_birth",
            "headerName": "Date of Birth",
            "width": 100,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.dependent_date_of_birth)
        },
        {
            "field": "dependent_relationship_type",
            "headerName": "Rel Type",
            "width": 110,
            "editable": false
        },
        {
            "field": "dependent_status",
            "headerName": "Status",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
            renderMemberStatus(params.row?.dependent_status)
        },
        {
            "field": "dependent_reason",
            "headerName": "Reason",
            "width": 300,
            "editable": false,
           

        },
       
    ];

    function getEmployeeRowID(row) {
        return row.rowID;
    }

    return (
        <>
            {desiredWidth ? (<>
                <div style={{ width: desiredWidth, height: desiredHeight, }}>
                    <DataGridPro
                        getRowId={getEmployeeRowID}
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}
                       
                        rows={rows}
                        columns={columns}
                       
                        sx={{
                            cursor: 'pointer',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                        slots={{

                            detailPanelExpandIcon: IoMdArrowDropright,
                            detailPanelCollapseIcon: IoMdArrowDropdown,
                        }}
                        getDetailPanelHeight={() => 'auto'}
                        
                        getDetailPanelContent={({ row }) => row.dependent_findings.length !== 0 ? (
                            <Box sx={{ ml: 4, height: 'fit-content', bgcolor: '#ffffff' }}>
                                {row.dependent_findings?.map((item) => {
                                    return (<>
                                        <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, color: '#737373' }}>{item?.auditFindingTitle}</Typography>
                                        <Box sx={{ ml: 4, mt: 2, height: 'fit-content', width: '50VW', bgcolor: '#ffffff', border: "1px solid #EAEAEA", }}>
                                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, mr: 2, color: '#64748B' }}>{item?.auditFindingText}</Typography>

                                        </Box>
                                      
                                       
                                        </>
                            
                                    )
                                }) }
                                
                        

                               
                            </Box>
                        ) : (<>
                                <Box sx={{ ml: 4, height: 'fit-content', bgcolor: '#ffffff' }}>
                                <Box sx={{ ml: 0, mt: 2, mb:2, height: '2VH', width: '50VW', bgcolor: '#ffffff', }}>
                                    <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, color: '#737373' }}>No Findings Available</Typography>
                                    </Box>
                                </Box>
                            </>)

                        }
                    />
                </div>
            </>) : (<>
                <div style={{ height: 550, width: "70VW" }}>
                    <DataGridPro
                            getRowId={getEmployeeRowID}
                        disableSelectionOnClick={true}
                        disableMultipleRowSelection={true}
                       
                        rows={rows}
                        columns={columns}
                       
                        sx={{
                            cursor: 'pointer',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important'
                            }
                        }}
                            slots={{
                              
                                detailPanelExpandIcon: IoMdArrowDropright,
                                detailPanelCollapseIcon: IoMdArrowDropdown,
                            }}
                            getDetailPanelHeight={() => 250}
                            getDetailPanelContent={({ row }) => row.dependent_findings.length !== 0 ? (
                                <Box sx={{ ml: 4, height: 'fit-content', bgcolor: '#ffffff' }}>
                                    {row.dependent_findings?.map((item) => {
                                        return (<>
                                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, color: '#737373' }}>{item?.auditFindingTitle}</Typography>
                                            <Box sx={{ ml: 4, mt: 2, height: 'fit-content', width: '50VW', bgcolor: '#ffffff', border: "1px solid #EAEAEA", }}>
                                                <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, mr: 2, color: '#64748B' }}>{item?.auditFindingText}</Typography>

                                            </Box>


                                        </>

                                        )
                                    })}




                                </Box>
                            ) : (<>
                                <Box sx={{ ml: 4, height: 'fit-content', bgcolor: '#ffffff' }}>
                                    <Box sx={{ ml: 0, mt: 2, mb: 2, height: '2VH', width: '50VW', bgcolor: '#ffffff', }}>
                                        <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ ml: 6, mt: 2, mb: 2, color: '#737373' }}>No Findings Available</Typography>
                                    </Box>
                                </Box>
                            </>)

                            }
                    />
                </div>
            </>)}
        </>
    );
}

export default FamilyDetailsMembersTable;