import React, { useContext, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { EmployeeSearchContext } from '../../pages/EmployeeSearch';
import Divider from '@mui/material/Divider';


import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import { CgOptions } from "react-icons/cg";
import { format, formatISO } from 'date-fns'
import { styled, useTheme } from '@mui/material/styles';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import List from '@mui/material/List';
import Tooltip from "@mui/material/Tooltip";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';

import { MdFilterList } from "react-icons/md";
import { PersistentPreviousSearchContext } from '../../App';

function EmployeeSearchTableMobile({ tableData, desiredWidth, desiredHeight }) {

    const {
       








        haveAPISearchData,




    } = useContext(EmployeeSearchContext);

    const { userPersistentPreviousSearchLocal,
        setUserPersistentPreviousSearchLocal,
        handleUpdatePersistentPreviousSearchParameters,
        userPersistentPreviousSearchLocalLogicalOperator,
        setUserPersistentPreviousSearchLocalLogicalOperator,
        handleUpdatePersistentPreviousSearchLogicalOperator
    } = useContext(PersistentPreviousSearchContext);

    const [openMobile, setOpenMobile] = React.useState(false);

    const handleDrawerOpenMobile = () => {
        setOpenMobile(true);
    };

    const handleDrawerCloseMobile = () => {
        setOpenMobile(false);
    };


    const { id, auditName } = useParams();

    function getDependentID(row) {
        return row.rowID;
    }

    function getEmployeeRowID(row) {
        return row.rowID;
    }

    function getDependentRelationship(row) {
        console.log("ROW -----", row);

        if (row.dependent_relationship === "S ") {
            return 'Spouse'
        } else if (row.dependent_relationship === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row.dependent_relationship}`
        }
    }

    function getPreviousSearchFieldName(field) {


        if (field === "last_name") {
            return 'LAST NAME: '
        } else if (field === "first_name") {
            return 'FIRST NAME: '
        } else if (field === "audit_family_id") {
            return 'AUDIT FAMILY ID: '
        } else if (field === "date_of_birth") {
            return 'DATE OF BIRTH: '
        } else if (field === "email") {
            return 'EMAIL: '
        } else {
            return field;
        }
    }

    const apiRef = useGridApiRef();

  

    const [previousSearchParameters, setPreviousSearchParameters] = useState(userPersistentPreviousSearchLocal?.length !== 0 ? userPersistentPreviousSearchLocal : []);

    function handleUpdatePreviousSearchParameters(updatedPreviousSearchParameters) {
        setPreviousSearchParameters(updatedPreviousSearchParameters);
    }

    const [previousSearchLogicalOperatorParameter, setPreviousSearchLogicalOperatorParameter] = useState(userPersistentPreviousSearchLocalLogicalOperator !== "" ? userPersistentPreviousSearchLocalLogicalOperator : "");

    function handleUpdatePreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter) {
        setPreviousSearchLogicalOperatorParameter(updatedPreviousSearchLogicalOperatorParameter);

    }

    const [employeeOnlySearch, setEmployeeOnlySearch] = React.useState(false);

    function handleUpdateEmployeeOnlySearch(newStatus) {
        setEmployeeOnlySearch(newStatus)
    }

    const [previousSearchWasEmployeeOnly, setPreviousSearchWasEmployeeOnly] = useState(false);

    function handleUpdatePreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus) {

        setPreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus);
    }




    const [mainSearchFilterModel, setMainSearchFilterModel] = React.useState([]);
    const [mainSearchFilterModelLogicalOperator, setMainSearchFilterModelLogicalOperator] = React.useState("");

    const [searchFirstNameExactMatch, setSearchFirstNameExactMatch] = React.useState(false);

    function handleUpdateSearchFirstNameExactMatch(newFirstExactMatchStatus) {
        setSearchFirstNameExactMatch(newFirstExactMatchStatus);
    }

    const [searchLastNameExactMatch, setSearchLastNameExactMatch] = React.useState(false);

    function handleUpdateSearchLastNameExactMatch(newLastExactMatchStatus) {
        setSearchLastNameExactMatch(newLastExactMatchStatus);
    }

    const [generateNewSearchFilterModel, setGenerateNewSearchFilterModel] = React.useState(false);

    const [searchValueAuditID, setSearchValueAuditID] = React.useState("");
    const [savedLastSearchValueAuditID, setSavedLastSearchValueAuditID] = React.useState("");

    function handleSearchValueAuditIDChange(event) {
        event.preventDefault();
        setSearchValueAuditID(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "audit_id",
        //        SearchValue: searchValueAuditID,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);

        //}

    }


    const [searchValueClient, setSearchValueClient] = React.useState("");

    function handleSearchValueClientChange(event) {

        setSearchValueClient(event.target.value);

        //if (generateNewSearchFilterModel == true) {
        //    const newFilterParameterItem = {
        //        FieldType: "client",
        //        SearchValue: searchValueClient,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    const [searchValueClientExactMatch, setSearchValueClientExactMatch] = React.useState(false);

    function handleUpdateSearchValueClientExactMatch(newExactMatchStatus) {
        setSearchValueClientExactMatch(newExactMatchStatus);
    }


    const [searchValueFirstName, setSearchValueFirstName] = React.useState("");

    function handleSearchValueFirstNameChange(event) {
        setSearchValueFirstName(event.target.value);
        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "first_name",
        //        SearchValue: searchValueFirstName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueLastName, setSearchValueLastName] = React.useState("");

    function handleSearchValueLastNameChange(event) {

        setSearchValueLastName(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "last_name",
        //        SearchValue: searchValueLastName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueDOB, setSearchValueDOB] = React.useState(null);

    function handleSearchValueDOBChange(value) {
        /* console.log("DOB CHANGE ------", event.target?.value)*/
        if (value !== null) {
            setSearchValueDOB(value);
        }


        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "date_of_birth",
        //        SearchValue: searchValueDOB,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueEmailAddress, setSearchValueEmailAddress] = React.useState("");


    function handleSearchValueEmailAddressChange(event) {

        setSearchValueEmailAddress(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: 'email',
        //        SearchValue: searchValueEmailAddress,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    function handleGetAndSetAllFiltersForUpdate() {

       

        apiRef.current.setFilterModel({
            items: []

        });

        let newMainSearchFilterModel = [];
        let newLogicalSearchOperator = null;


        if (searchValueAuditID !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "audit_family_id",
                value: searchValueAuditID,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueClient !== "") {
            if (searchValueClientExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueClient,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueClient,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            }

        }

        if (searchValueFirstName !== "") {
            if (searchFirstNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "first_name",
                    value: searchValueFirstName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueFirstName,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";

                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "first_name",
                    value: searchValueFirstName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueFirstName,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";

                }
            }


        }
        if (searchValueLastName !== "") {
            if (searchLastNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "last_name",
                    value: searchValueLastName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueLastName,
                        operator: 'equals'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "last_name",
                    value: searchValueLastName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                if (employeeOnlySearch !== true) {

                    let j = newMainSearchFilterModel?.length;
                    let newOtherItemID = j + 1;

                    const newSearchFilterModelItemOther = {
                        id: newOtherItemID,
                        field: "other",
                        value: searchValueLastName,
                        operator: 'contains'
                    };
                    newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                    newLogicalSearchOperator = "or";
                }
            }

        }
        if (searchValueDOB !== null) {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            /*let convertedForSearchFilterDate = searchValueDOB;*/
            let convertedForSearchFilterDate = convertUserProvidedDate(searchValueDOB)
            console.log("ConvertedSearchDate: ", convertedForSearchFilterDate);

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "date_of_birth",
                value: convertedForSearchFilterDate,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

            if (employeeOnlySearch !== true) {

                let j = newMainSearchFilterModel?.length;
                let newOtherItemID = j + 1;

                const newSearchFilterModelItemOther = {
                    id: newOtherItemID,
                    field: "other",
                    value: convertedForSearchFilterDate,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemOther];
                newLogicalSearchOperator = "or";
            }
        }
        if (searchValueEmailAddress !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'email',
                value: searchValueEmailAddress,
                operator: 'equals'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }

        handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalSearchOperator)





    }

    function handleClearSearchAndBackUpPreviousFilterModel() {
        console.log("CLEAR SEARCH AND BACK UP");
        //backup relevant values
        if (mainSearchFilterModel !== []) {
            handleUpdatePersistentPreviousSearchParameters(mainSearchFilterModel)
            handleUpdatePreviousSearchParameters(mainSearchFilterModel)
            if (mainSearchFilterModelLogicalOperator !== null) {
                handleUpdatePersistentPreviousSearchLogicalOperator(mainSearchFilterModelLogicalOperator)
                handleUpdatePreviousSearchLogicalOperatorParameter(mainSearchFilterModelLogicalOperator)
                console.log("BACKING UP PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
            }
            console.log("BACKING UP PREV SEARCH MODEL:", previousSearchParameters);



        }
        //then clear out the existing
        handleUpdateMainSearchFilterModel(null);
    }

    function handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalOperator) {

        setOpenMobile(false)

        if (newMainSearchFilterModel !== null) {
            //let newMainSearchFilterModel = mainSearchFilterModel;


            //let i = newMainSearchFilterModel?.length;
            //let newItemID = i + 1;

            //let newParameterFieldType = newFilterParameter?.FieldType;
            //let newParameterSearchValue = newFilterParameter?.SearchValue;
            //let newParameterOperatorValue = newFilterParameter?.OperatorValue;

            //let newSearchFilterModelItem = {
            //    id: newItemID,
            //    operator: newParameterOperatorValue,
            //    field: newParameterFieldType,
            //    value: newParameterSearchValue,
            //}
            if (newLogicalOperator !== null) {
                setMainSearchFilterModelLogicalOperator(newLogicalOperator)
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF - WITH LOGICAL OPERATOR: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel,
                    logicOperator: newLogicalOperator

                });
            } else {
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel

                });
            }
            /////
            //if (newMainSearchFilterModel !== []) {
            //    handleUpdatePreviousSearchParameters(newMainSearchFilterModel)
            //    if (newLogicalOperator !== null) {
            //        handleUpdatePreviousSearchLogicalOperatorParameter(newLogicalOperator)
            //    }

            //}

            //newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
            //if (generateNewSearchFilterModel == true) {


        } else if (newMainSearchFilterModel == null) {
            setMainSearchFilterModel([]);
            apiRef.current.setFilterModel({
                items: []

            });
            console.log("SETTING NEW APIREF NULL: ", null)
            setSearchValueAuditID("")
            setSearchValueClient("")
            setSearchValueFirstName("")
            setSearchValueLastName("")
            setSearchValueDOB(null)
            setSearchValueEmailAddress("")



            setGenerateNewSearchFilterModel(false)
        }
        //console.log("PREV SEARCH MODEL:", previousSearchParameters);
        //console.log("PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
    }




    function handleGenerateNewMainSearchFilterModel() {
        /* setGenerateNewSearchFilterModel(true)*/
        setMainSearchFilterModel([])
        apiRef.current.setFilterModel({
            items: []

        });



        handleGetAndSetAllFiltersForUpdate()
    }

    function handleGeneratePreviousMainSearchFilterModel() {

        setOpenMobile(false)

        apiRef.current.setFilterModel({
            items: []

        });
        if (previousSearchParameters?.length !== 0) {

            if (previousSearchLogicalOperatorParameter !== "") {
                console.log("SETTING APIREF W/ PREV SEARCH PARAMS & OPERATOR: ", previousSearchParameters, previousSearchLogicalOperatorParameter)
                apiRef.current.setFilterModel({
                    items: previousSearchParameters,
                    logicOperator: previousSearchLogicalOperatorParameter

                });
            } else {
                console.log("SETTING APIREF W/ JUST PREV SEARCH PARAMS: ", previousSearchParameters)
                apiRef.current.setFilterModel({
                    items: previousSearchParameters

                });
            }


        }



    }


    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Employee Search Report</Box>
                <Box sx={{ mt: 1 }}>Use the form above to generate a report</Box>
            </StyledGridOverlay>
        );
    }

    function CustomNoResultsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Sorry, that search did not yield any results.</Box>
                <Box sx={{ mt: 1 }}>Please try again with different Search Parameters</Box>
            </StyledGridOverlay>
        );
    }

    //const {
    //    openMobile,
    //    handleDrawerCloseMobile,
    //    handleDrawerOpenMobile,
    //    handleUpdateEmployeeOnlySearch,
    //    employeeOnlySearch,
    //    handleUpdateSearchValueClientExactMatch,
    //    searchValueClientExactMatch,
    //    handleUpdateSearchFirstNameExactMatch,
    //    searchFirstNameExactMatch,
    //    handleUpdateSearchLastNameExactMatch,
    //    searchLastNameExactMatch,
    //    handleSearchValueAuditIDChange,
    //    handleSearchValueClientChange,
    //    handleSearchValueFirstNameChange,
    //    handleSearchValueLastNameChange,
    //    handleSearchValueEmailAddressChange,
    //    handleSearchValueDOBChange,
    //    handleGeneratePreviousMainSearchFilterModel,
    //    previousSearchParameters,
    //    handleClearSearchAndBackUpPreviousFilterModel,
    //    handleGenerateNewMainSearchFilterModel
    //} = useContext(EmployeeSearchContext);

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

    function convertUserProvidedDate(date) {
        if (!date) {
            return '';
        }
        return formatISO(new Date(date), { representation: 'date' })
    }

    const dataGridColumns = [
        {
            "field": "audit_family_id",
            "headerName": "Audit ID",
            "width": 90
        },
        {
            "field": "first_name",
            "headerName": "First Name",
            "width": 120,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "Date of Birth",
            "width": 180,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 250,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 150,
            "editable": false
        },
      
        {
            "field": "address",
            "headerName": "Address",
            "width": 450,
            "editable": false
        },
       
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {
                /* console.log('row', row);*/
                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];

    const dataGridColumnsMediumOrSmall = [
        {
            "field": "audit_family_id",
            "headerName": "Audit Family",
            "width": 90
        },
        {
            "field": "first_name",
            "headerName": "First Name",
            "width": 120,
            "editable": false
        },
        {
            "field": "last_name",
            "headerName": "Last Name",
            "width": 150,
            "editable": false
        },
        {
            "field": "date_of_birth",
            "headerName": "DOB",
            "width": 160,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.date_of_birth)
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 230,
            "editable": false
        },
        {
            "field": "relationship",
            "headerName": "Relationship",
            "width": 100,
            "editable": false
        },
       
        {
            "field": "address",
            "headerName": "Address",
            "width": 450,
            "editable": false
        },
       
        {
            "field": "audit_end_date",
            "headerName": "End Date",
            "width": 150,
            "editable": false,
            renderCell: (params) =>
                renderDate(params.row?.audit_end_date)

        },
        {
            "field": "other",
            "headerName": "_",
            "width": 260,
            valueGetter: (value, row) => {
                /*console.log('row', row);*/
                return JSON.stringify({
                    dependent_first_names: row.dependent_data.map((item) => item.dependent_first_name),
                    dependent_last_names: row.dependent_data.map((item) => item.dependent_last_name),
                    dependent_dates_of_birth: row.dependent_data.map((item) => item.dependent_date_of_birth),
                    dependent_relationships: row.dependent_data.map((item) => item.dependent_relationship),

                });
            },
        },
    ];


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSearchSettings = Boolean(anchorEl);

    const handleClickSearchSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSearchSettings = () => {
        setAnchorEl(null);
    };




    const rows = tableData;
    const navigate = useNavigate();


    const handleNavigation = (params) => {

        /* console.log(params);*/

        navigate(`/report/${String(params.row.auditId)}/${params.row.auditName}`);

    };

    let apiFamilyData = useRef(null);



    /////
    const [rowSelectedMemberData, setRowSelectedMemberData] = useState()

    function handleUpdateRowSelectedMemberData(updatedData) {
        let tempData = [];
        console.log("updatedRowSelectedMemberData: ", updatedData)
        tempData = [...tempData, updatedData]
        console.log("setting this tempData: ", tempData)
        setRowSelectedMemberData(tempData);
    }

    function handleNavigateToFamilyDetails(navFamId, navEmpId) {

        console.log("leaving, REAL RowSelectedMemberData: ", rowSelectedMemberData)

        navigate(`/report/${String(id)}/${auditName}/search/${String(navFamId)}/${String(navEmpId)}`);

    };

    const handleUpdateMemberRowDataAndNavigate = (params) => {
        handleUpdatePersistentPreviousSearchParameters(mainSearchFilterModel)
        if (mainSearchFilterModelLogicalOperator !== null) {
            handleUpdatePersistentPreviousSearchLogicalOperator(mainSearchFilterModelLogicalOperator)

            console.log("BACKING UP PERSIST PREV SEARCH LOGICAL OPERATOR:", mainSearchFilterModelLogicalOperator);
        }

        let navigateAudtFamilyId = params.row.audit_family_id;
        let navigateEmployeeId = params.row.employeeID;

        let newRowSelectedMemberData = {
            "rowEmployeeID": params.row.employeeID,
            "rowAuditFamilyID": params.row.audit_family_id,
            "rowAuditEndDate": params.row.audit_end_date,
            "rowAddress": params.row.address,
            "rowClient": params.row.client,
            "rowEmail": params.row.email,
            "rowEmployeeFirstName": params.row.first_name,
            "rowEmployeeLastName": params.row.last_name,
            "rowEmployeeDateOfBirth": params.row.date_of_birth,
        }
        setRowSelectedMemberData([newRowSelectedMemberData]);
        handleUpdateRowSelectedMemberData(newRowSelectedMemberData);



        /* console.log(params);*/
        console.log("CLICK-ROW", params.row);
        console.log("leaving, newRowSelectedMemberData: ", newRowSelectedMemberData)


        handleNavigateToFamilyDetails(navigateAudtFamilyId, navigateEmployeeId)
    }

   

 



    return (
        <>
            {openMobile ? (<>
                <Box

                    sx={{
                        mt: 6,
                        bgcolor: '#F8FAFC',
                        width: '100VW',
                        zIndex: 1500,
                        height: '88VH',


                    }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">
                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, ml: 3 }}> Search Filters</Typography>
                        </div>

                        <Tooltip title="Close Sidebar">
                            <IconButton onClick={handleDrawerCloseMobile} sx={{ color: '#5b5b5b', mr: 8 }} aria-label="Close Sidebar">
                                <RxCross2 />
                            </IconButton>
                        </Tooltip>

                    </div>
                    <Box sx={{ mt: 2, ml: 3 }}>
                        <Accordion sx={{ width: '78VW', }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"

                            >
                                Filter Options
                            </AccordionSummary>
                            <AccordionActions

                            >
                                <Button
                                    onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}

                                    sx={{ bgcolor: '#ffffff', }}
                                >
                                    Employee-Only Search
                                    <Switch
                                        checked={employeeOnlySearch}


                                    />

                                </Button>
                            </AccordionActions>
                            {/*<AccordionActions*/}

                            {/*>*/}
                            {/*    <Button*/}
                            {/*        onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                            {/*        sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                            {/*    >*/}
                            {/*        Client Name Exact*/}
                            {/*        <Switch*/}
                            {/*            checked={searchValueClientExactMatch}*/}


                            {/*        />*/}

                            {/*    </Button>*/}
                            {/*</AccordionActions>*/}
                           
                            <AccordionActions

                            >
                                <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}
                                    sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}
                                >
                                    First Name Exact
                                    <Switch
                                        checked={searchFirstNameExactMatch}


                                    />

                                </Button>
                            </AccordionActions>
                            <AccordionActions

                            >
                                <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}

                                    sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>
                                    Last Name Exact
                                    <Switch
                                        checked={searchLastNameExactMatch}


                                    />

                                </Button>
                            </AccordionActions>
                            {/*<AccordionDetails>*/}
                            {/*    <List*/}
                            {/*        style={{ marginTop: '0.5VH' }}*/}
                            {/*    >*/}
                            {/*        <Button*/}
                            {/*            onClick={() => handleUpdateEmployeeOnlySearch(!employeeOnlySearch)}*/}

                            {/*            sx={{ bgcolor: '#ffffff', }}*/}
                            {/*        >*/}
                            {/*            Employee-Only Search*/}
                            {/*            <Switch*/}
                            {/*                checked={employeeOnlySearch}*/}


                            {/*            />*/}

                            {/*        </Button>*/}
                            {/*    </List>*/}
                            {/*    <List*/}
                            {/*        style={{ marginTop: '0.5VH' }}*/}
                            {/*    >*/}
                            {/*        <Button*/}
                            {/*            onClick={() => handleUpdateSearchValueClientExactMatch(!searchValueClientExactMatch)}*/}
                            {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                            {/*        >*/}
                            {/*            Client Name Exact*/}
                            {/*            <Switch*/}
                            {/*                checked={searchValueClientExactMatch}*/}


                            {/*            />*/}

                            {/*        </Button>*/}
                            {/*    </List>*/}
                            {/*    <List*/}
                            {/*        style={{ marginTop: '0.5VH' }}*/}
                            {/*    >*/}
                            {/*        <Button onClick={() => handleUpdateSearchFirstNameExactMatch(!searchFirstNameExactMatch)}*/}
                            {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}*/}
                            {/*        >*/}
                            {/*            First Name Exact*/}
                            {/*            <Switch*/}
                            {/*                checked={searchFirstNameExactMatch}*/}


                            {/*            />*/}

                            {/*        </Button>*/}
                            {/*    </List>*/}
                            {/*    <List*/}
                            {/*        style={{ marginTop: '0.5VH' }}*/}
                            {/*    >*/}
                            {/*        <Button onClick={() => handleUpdateSearchLastNameExactMatch(!searchLastNameExactMatch)}*/}

                            {/*            sx={{ bgcolor: '#ffffff', mt: '0.25VH' }}>*/}
                            {/*            Last Name Exact*/}
                            {/*            <Switch*/}
                            {/*                checked={searchLastNameExactMatch}*/}


                            {/*            />*/}

                            {/*        </Button>*/}
                            {/*    </List>*/}
                            {/*</AccordionDetails>*/}
                            {/*<AccordionDetails>*/}


                            {/*</AccordionDetails>*/}
                        </Accordion>
                    </Box>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >

                        {/* <CustomMobileInputAuditID value={searchValueAuditID} onChange={(e) => handleSearchValueAuditIDChange(e)} />*/}

                        <TextField

                            label="Audit ID"
                            variant="outlined"
                            value={searchValueAuditID}
                            onChange={handleSearchValueAuditIDChange}

                            sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}
                        />


                    </List>


                    {/*<List*/}
                    {/*    style={{ marginTop: '0.5VH' }}*/}
                    {/*>*/}

                    {/*    <TextField*/}
                    {/*        label="Client"*/}
                    {/*        variant="outlined"*/}
                    {/*        value={searchValueClient}*/}
                    {/*        onChange={handleSearchValueClientChange}*/}

                    {/*        sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}*/}


                    {/*    />*/}

                    {/*</List>*/}

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="First Name"
                            variant="outlined"
                            value={searchValueFirstName}
                            onChange={handleSearchValueFirstNameChange}

                            sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="Last Name"
                            variant="outlined"
                            value={searchValueLastName}
                            onChange={handleSearchValueLastNameChange}

                            sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                label="Date of Birth"
                                format="MM/DD/YYYY"
                                value={searchValueDOB}
                                onChange={(value) => handleSearchValueDOBChange(value)}
                                sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}

                            />
                        </LocalizationProvider>


                    </List>

                    <List
                        style={{ marginTop: '0.5VH' }}
                    >


                        <TextField
                            label="Email Address"
                            variant="outlined"
                            value={searchValueEmailAddress}
                            onChange={handleSearchValueEmailAddressChange}

                            sx={{ ml: 3, mr: '1VW', mt: 1, width: '77VW', bgcolor: '#FFFFFF' }}


                        />


                    </List>

                   
                    <Tooltip title={userPersistentPreviousSearchLocal !== [] ? userPersistentPreviousSearchLocal?.map((item) => {
                        if (item?.field !== 'other') {
                            return (<>
                                {getPreviousSearchFieldName(item?.field.toString())}{item?.value.toString()} < br />
                            </>)
                        }
                    }) : null} placement="top">
                        <Button
                            variant="contained"
                            label="PREVIOUS SEARCH"
                            sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#3B82F6', mt: 3, }}
                            onClick={() => handleGeneratePreviousMainSearchFilterModel()}
                            disabled={(previousSearchParameters.length === 0) ? true : false}
                        >
                            PREVIOUS SEARCH
                        </Button>
                    </Tooltip>
                    <Button
                        variant="contained"
                        label="CLEAR SEARCH"
                        sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#FFFFFF', color: '#000000', mt: 3, }}
                        onClick={() => handleClearSearchAndBackUpPreviousFilterModel()}
                    >
                        CLEAR SEARCH
                    </Button>


                    <Button
                        variant="contained"
                        label="GENERATE SEARCH"
                        sx={{ ml: 3, mr: '1VW', width: '77VW', height: '5VH', bgcolor: '#3B82F6', mt: 3, }}
                        onClick={() => handleGenerateNewMainSearchFilterModel()}
                    >
                        GENERATE SEARCH
                    </Button>
                </Box>

            </>) : (<></>)}
            {/*  MOBILE PAGE TITLES/HEADINGS*/}
            <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                <div className="row-no-left-margin">
                    <div className="row-left">

                        <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> {`Search ${auditName.substring(0, 3)}`}</Typography>
                        {/*<Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> {`${auditName.substring(0, 3)}`}</Typography>*/}


                    </div>
                    <div className="row-right">


                        <>
                            <Tooltip title="Open Search Filter Options">
                                <Button
                                    onClick={handleDrawerOpenMobile}
                                    variant="contained"
                                    disableElevation sx={{
                                        height: '3.5VH', width: '3.5VH',
                                        bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                        ':hover': {
                                            bgcolor: '#dddddd', // theme.palette.primary.main
                                            color: '#ffffff',
                                            boxShadow: 0,
                                        },
                                    }}
                                    style={{ textTransform: 'none' }}
                                >
                                    <MdFilterList />
                                </Button>


                            </Tooltip>

                        </>
                    </div>
                </div>
                <Box sx={{ height: '2VH' }}></Box>
            </Container>
            <Container sx={{ display: { xs: 'block', md: 'none' } }}>

                {employeeOnlySearch === true ? (<>


                    <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                        <DataGridPro
                            onRowClick={(row) => handleUpdateMemberRowDataAndNavigate(row)}
                            getRowId={getEmployeeRowID}
                            columnVisibilityModel={{
                                // Hide columns status and traderName, the other columns will remain visible
                                other: false,
                            }}

                            onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                            columns={dataGridColumns}
                            rows={tableData.current}
                            sx={{
                                '& .MuiDataGrid-columnContainer': {
                                    bgcolor: '#F1F5F9',
                                    fontWeight: 800,
                                },
                            }}
                            slots={{
                                noResultsOverlay: CustomNoResultsOverlay

                            }}
                            apiRef={apiRef}



                        />


                    </Box>
                </>)
                    : (<>

                        <Box sx={{ height: 600, width: '78VW', ml: 3 }}>
                            <DataGridPro
                                onRowClick={(row) => handleUpdateMemberRowDataAndNavigate(row)}
                                getRowId={getEmployeeRowID}
                                columnVisibilityModel={{
                                    // Hide columns status and traderName, the other columns will remain visible
                                    other: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={tableData.current}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}

                                apiRef={apiRef}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                                getDetailPanelHeight={() => 250}
                                getDetailPanelContent={({ row }) => row.dependent_data != [] ? (
                                    <Box sx={{ height: 'fit-content', bgcolor: '#ffffff' }}>
                                        <DataGridPro
                                            density="compact"
                                            getRowId={getDependentID}
                                            columns={[
                                                { field: 'dependent_first_name', headerName: 'First Name', "width": 110 },
                                                { field: 'dependent_last_name', headerName: 'Last Name', "width": 160 },
                                                {
                                                    field: "dependent_relationship",
                                                    headerName: 'Relationship',
                                                    "width": 150,
                                                    renderCell: (params) =>
                                                        getDependentRelationship(params.row)


                                                },
                                                {
                                                    field: "dependent_date_of_birth", headerName: 'DOB', "width": 110, renderCell: (params) =>
                                                        renderDate(params.row?.dependent_date_of_birth)
                                                },
                                            ]}
                                            rows={row?.dependent_data}
                                            sx={{
                                                mt: '2.5VH',
                                                ml: '2.5VW',

                                                width: '80VW',

                                                bgcolor: '#F8FAFC',
                                                boxShadow: 2,
                                                border: 2,
                                                borderColor: 'primary.light',
                                                '& .MuiDataGrid-columnContainer': {
                                                    bgcolor: '#F1F5F9',
                                                    fontWeight: 800,
                                                },
                                                '& .MuiDataGrid-cell:hover': {
                                                    color: 'primary.main',
                                                },
                                            }}
                                            hideFooter
                                        />
                                    </Box>
                                ) : (<></>)

                                }

                            />
                        </Box>
                    </>)}

           
            </Container>
        </>
    );
}





export default EmployeeSearchTableMobile;