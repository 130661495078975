import React, { useState, useEffect, PureComponent, useContext } from 'react'

import { useParams, useNavigate, Navigate, Link, Outlet, useLocation } from "react-router-dom";
import { loginRequest } from '../authConfig';
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { useAnimation, motion } from "framer-motion";
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import DEA_MOCK_DATA from "../data/DEA_MOCK_DATA.json"
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BsClock } from "react-icons/bs";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import useFetch from "../hooks/useFetch";
import { ToastContainer, toast, Bounce } from "react-toastify";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import { SidebarContext } from "../App";
import { RoutesContext } from "../App";
import { RolesContext } from "../App";
import { PermissionsContext } from "../App";


function Report({ onChange, children }) {

    const {

        setSidebarTitle,
        sidebarActions,
        setSidebarActions,
        setClientSearchData
    } = useContext(SidebarContext);

    useEffect(() => {
        setSidebarTitle("Clients")
    }, []);


    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);


    const [reports, setReports] = useState([]);
    const initStatusTally = [{ id: 'No Response', value: 0, color: "hsl(85, 70%, 50%)" }, { id: 'Incomplete', value: 4000, color: "hsl(287, 70%, 50%)" }, { id: 'Complete', value: 100, color: "hsl(248, 70%, 50%)" }, { id: 'Ineligible', value: 500 }, { id: 'Suspend', value: 0, color: "hsl(142, 70%, 50%)" }]
    const [statusTally, setStatusTally] = useState(initStatusTally)
    const initEmailTally = [{ id: 'Yes', value: 928, color: "hsl(248, 70%, 50%)" }, { id: 'No', value: 207, color: "hsl(85, 70%, 50%)" }]
    const [emailTally, setEmailTally] = useState(initEmailTally)
    const initTotalTally = [{ type: "Employee", value: 0 }, { type: "Dependent", value: 0 }, { type: "Combined", value: 0 }]
    const [totalTally, setTotalTally] = useState(initTotalTally)
    const calendarDates = [{ value: 22, day: "2023-01-17" }, { value: 49, day: "2023-01-23" }, { value: 105, day: "2023-02-13" }, { value: 53, day: "2023-02-15" }, { value: 2, day: "2023-02-17" }, { value: 86, day: "2023-02-21" }];
    const [input, setInput] = useState('');
    const { accounts, instance, inProgress, } = useMsal();
    const iniAudit = [{ auditId: '', auditName: '', clientName: '' }]
    const [activeAudits, setActiveAudits] = useState([]);
    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();


    const { newRoutes, setRoutes } = useContext(RoutesContext);
    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: false
        },
        //{
        //    id: 1,
        //    label: "Processing",
        //    path: "processing",
        //    icon: <AssignmentTurnedInIcon />,
        //    isActive: false,
        //    style: '',
        //    isNested: false
        //},
        //{
        //    id: 2,
        //    label: "Search All",
        //    path: "globalsearch",
        //    icon: <TravelExploreIcon />,
        //    isActive: false,
        //    style: 'active-navLink border-bottom__thick-blue',
        //    isNested: false
        //}
    ]);

    useEffect(() => {

        if ((roles === "CLIENT")) {
            /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/

            if (permissions?.length === 1) {

                navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, {replace: true});
            }


        }


        if ((roles === "SERVICES")) {
            navigate('/processing');
        }

        if ({pageRoutes} !== {newRoutes} && (roles === "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 2,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])
            //console.log("newroutes - : report", {newRoutes});
            //console.log("pageroutes - report: ", { pageRoutes });
        }
        
    }, []);


    const { responseData: responseDataProj , responseError: responseErrorProj , loading: loadingDataProj , getData: getDataProj } = useFetch();

   
    useEffect(() => {
       
        if (accounts.length > 0) {
            const account = accounts[0].idTokenClaims;
            getDataProj(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAudits', 'GET');//REACT_APP_BACK_URL
        }

    }, []);

    useEffect(() => {
        if (loadingDataProj === false && responseErrorProj === null && responseDataProj !== null) {
            
            setActiveAudits(responseDataProj?.activeAudits);
            setClientSearchData(responseDataProj?.activeAudits);

        }
    }, [loadingDataProj]);
   /* console.log(activeAudits);*/
   

    //status pie chart
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#D10A22'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const { role, setRole } = useContext(RolesContext);

    return (
      
        <>
           
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >

                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, mr: 0, display: { xs: 'none', md: 'block' } }}>

                    {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                        <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 2.5, }}><BsClock style={{ fontSize: '0.8em' }} /> Recent Clients </Typography>
                    </>) : (<>
                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                            <Skeleton height={30} width={180} />
                        </SkeletonTheme>
                    </>)}
                </Container >


               
                <Container sx={{ ml: 0, mr: 0 }}>
                    <Grid container spacing={3} sx={{ mt: 1, mb: 5, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                            {activeAudits?.map((audit, index) => (
                                index < 3 && (
                                    <Grid item xs={4} key={audit.auditId}>
                                    
                                            <ProjectCard
                                                projectClientName={audit.auditName}
                                                projectClient={audit.clientName}
                                                projectLink={`${String(audit.auditId)}/${audit.auditName}`}
                                                desiredCardWidth={300}
                                                desiredCardHeight={100}
                                            />
                                     
                                    </Grid>
                                )
                            ))}
                        </>) : (<>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={300} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={300} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={300} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                        </>)}

                      

                    </Grid>
                </Container>

                {/* DESKTOP TABLE*/}
               
                    <Container sx={{ ml: 0, mr: 0 }}>
                        <Grid container spacing={1} sx={{ mt: 1, mb: 0.5, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                        <Grid item xs={12}>
                            {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                                <ProjectTable
                                    tableData={activeAudits}
                                    desiredHeight={'50VH'}
                                    desiredWidth={'75VW'}
                                />
                            </>) : (<>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={360} width={'70VW'} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                            </>)}
                               
                            </Grid>
                        </Grid>
                    </Container>
             
               

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>




                {isAuthenticated ? (
                    <></>
                ) : (
                    <Navigate to="/" replace />
                )}
                <Outlet />

            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', bgcolor: '#FAFAFA', flexGrow: 1, paddingTop: 1, mt: 4.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}
               
                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>

                    {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                        <Typography textAlign="left" gutterBottom={true} component="h1" fontWeight="600" fontSize="1.5em" sx={{ mt: 2.5, }}><BsClock style={{ fontSize: '0.8em' }} /> Recent Clients</Typography>
                    </>) : (<>
                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                            <Skeleton height={30} width={180} />
                        </SkeletonTheme>
                    </>)}
                </Container >

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Project Cards - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 2.55, mr: 0, display: { xs: 'block', md: 'none' } }}>
                        {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                            {activeAudits?.map((audit, index) => (
                                index < 3 && (
                                    <Grid item xs={12} key={audit.auditId}>
                                      
                                            <ProjectCard
                                                projectClientName={audit.auditName}
                                                projectClient={audit.clientName}
                                                projectLink={`${String(audit.auditId)}/${audit.auditName}`}
                                                desiredCardHeight={100}
                                            />
                                      
                                    </Grid>
                                )
                            ))}
                        </>) : (<>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={'75VW'} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={'75VW'} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="row-no-left-margin">
                                        <div className="center">
                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                <Skeleton height={100} width={'75VW'} />
                                            </SkeletonTheme>
                                        </div>
                                    </div>
                                </Grid>
                        </>)}

                    </Grid>
                </Container>

                {/* MOBILE TABLE*/}

                <Grid container spacing={1} sx={{ mt: 1, mb: 2.5, ml: 0.75, mr: 10, display: { xs: 'block', md: 'none', xl: 'none' } }}>
                    <Grid item xs={12}>
                        {(activeAudits?.length > 0) && (responseDataProj?.activeAudits !== null) && (loadingDataProj === false) ? (<>
                            <ProjectTable
                                tableData={activeAudits}
                                desiredWidth={'84VW'}
                                desiredHeight={500}
                            />
                        </>) : (<>
                                <div className = "row-no-left-margin">
                                
                                        <SkeletonTheme baseColor = "#EFF6FF" highlightColor = "ffffff">
                                            <Skeleton height = { 360 } width = { '75VW' } />
                                        </SkeletonTheme>
                                   
                                </div> 
                        </>)}
                        
                    </Grid>
                </Grid>

                {isAuthenticated ? (
                    <></>
                ) : (
                    <Navigate to="/" replace />
                )}
                <Outlet />
            </Box>
            <ToastContainer
                position="bottom-center"
                autoClose={10000}
                theme="colored"
                closeOnClick
                hideProgressBar
                pauseOnHover={false}
                newestOnTop={false}
                transition={Bounce}

            />
        </>
       

    )
}
export default Report